import dayjs from "dayjs";
import "./ShowCardCalendar.css";

interface ContainerProps {
  date: Date;
}

const ShowCardCalendar: React.FC<ContainerProps> = (props) => {
  const getDay = () => {
    return dayjs(props.date).date();
  };
  const getMonth = () => {
    switch (dayjs(props.date).format("MM")) {
      case "01":
        return "JAN";
      case "02":
        return "FEV";
      case "03":
        return "MAR";
      case "04":
        return "AVR";
      case "05":
        return "MAI";
      case "06":
        return "JUIN";
      case "07":
        return "JUI";
      case "08":
        return "AOU";
      case "09":
        return "SEP";
      case "10":
        return "OCT";
      case "11":
        return "NOV";
      case "12":
        return "DEC";
      default:
        return "-";
    }
  };
  const getYear = () => {
    return dayjs(props.date).year();
  };

  return (
    <div className="show-card-calendar" data-testid="show-card-calendar">
      <div className="show-card-calendar__day-container">{getDay()}</div>
      <div className="show-card-calendar__month-container">{getMonth()}</div>
      <div className="show-card-calendar__year-container">{getYear()}</div>
    </div>
  );
};

export default ShowCardCalendar;
