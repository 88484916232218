import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import React, { useContext } from "react";
import HomeContext from "../../../contexts/home.ctx";
import "./HomeSegments.css";

interface ContainerProps {}

const HomeSegments: React.FC<ContainerProps> = (props) => {
  const homeCtx = useContext(HomeContext);

  return (
    <div className="home-segments" data-testid="home-segments">
      <div className="home-segments__segments">
        <IonSegment
          mode="ios"
          value={homeCtx.currentSegment}
          onIonChange={(e) =>
            homeCtx.currentSegmentSelectionHandler(e.detail.value)
          }
        >
          <IonSegmentButton value={"TODAY"}>
            <IonLabel>Aujourd'hui</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value={"COMING"}>
            <IonLabel>À Venir</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value={"PAST"}>
            <IonLabel>Précédents</IonLabel>
          </IonSegmentButton>
        </IonSegment>
      </div>
    </div>
  );
};

export default HomeSegments;
