import { useContext, useEffect, useState } from "react";
import LiveContext from "../../../contexts/live.ctx";
import getSymbolFromCurrency from "../../../helpers/getSymbolFromCurrency";
import { BottomPanelMode } from "../../../pages/Live/LivePage";
import "./LiveAmounts.css";

interface ContainerProps {
  onSelect: (amount: number) => void;
}

const LiveAmounts: React.FC<ContainerProps> = (props) => {
  const liveCtx = useContext(LiveContext);
  const [amounts, setAmounts] = useState({
    firstAmount: 0,
    secondAmount: 0,
    thirdAmount: 0,
    fourthAmount: 0,
    freeAmount: false,
  });

  useEffect(() => {
    setAmounts({
      firstAmount: liveCtx.liveShow.hasPledgesPresets
        ? liveCtx.liveUserData.customAmounts?.firstAmount ||
          liveCtx.liveShow.firstPledgeAmount
        : 0,
      secondAmount: liveCtx.liveShow.hasPledgesPresets
        ? liveCtx.liveUserData.customAmounts?.secondAmount ||
          liveCtx.liveShow.secondPledgeAmount
        : 0,
      thirdAmount: liveCtx.liveShow.hasPledgesPresets
        ? liveCtx.liveUserData.customAmounts?.thirdAmount ||
          liveCtx.liveShow.thirdPledgeAmount
        : 0,
      fourthAmount: liveCtx.liveShow.hasPledgesPresets
        ? liveCtx.liveUserData.customAmounts?.fourthAmount ||
          liveCtx.liveShow.fourthPledgeAmount
        : 0,
      freeAmount:
        liveCtx.liveUserData.customAmounts?.freeAmount ||
        liveCtx.liveShow.hasFreePledgeAmount,
    });
  }, [
    liveCtx.liveUserData.customAmounts,
    liveCtx.liveShow.hasPledgesPresets,
    liveCtx.liveShow.firstPledgeAmount,
    liveCtx.liveShow.secondPledgeAmount,
    liveCtx.liveShow.thirdPledgeAmount,
    liveCtx.liveShow.fourthPledgeAmount,
    liveCtx.liveShow.hasFreePledgeAmount,
  ]);

  return (
    <div className="live-amounts" data-testid="live-amounts">
      {amounts.freeAmount && (
        <div className="live-amounts__item" onClick={() => props.onSelect(-1)}>
          Montant Libre
        </div>
      )}
      {amounts.firstAmount > 0 && (
        <div
          className="live-amounts__item"
          onClick={() => props.onSelect(amounts.firstAmount)}
        >
          {`${
            isNaN(amounts.firstAmount / 100)
              ? "-"
              : (amounts.firstAmount / 100).toLocaleString(undefined, {
                  maximumFractionDigits: 0,
                })
          } ${getSymbolFromCurrency(liveCtx.liveShow.pledgesCurrency) || "€"}`}
        </div>
      )}

      {amounts.secondAmount > 0 && (
        <div
          className="live-amounts__item"
          onClick={() => props.onSelect(amounts.secondAmount)}
        >{`${
          isNaN(amounts.secondAmount / 100)
            ? "-"
            : (amounts.secondAmount / 100).toLocaleString(undefined, {
                maximumFractionDigits: 0,
              })
        } ${
          getSymbolFromCurrency(liveCtx.liveShow.pledgesCurrency) || "€"
        }`}</div>
      )}

      {amounts.thirdAmount > 0 && (
        <div
          className="live-amounts__item"
          onClick={() => props.onSelect(amounts.thirdAmount)}
        >{`${
          isNaN(amounts.thirdAmount / 100)
            ? "-"
            : (amounts.thirdAmount / 100).toLocaleString(undefined, {
                maximumFractionDigits: 0,
              })
        } ${
          getSymbolFromCurrency(liveCtx.liveShow.pledgesCurrency) || "€"
        }`}</div>
      )}
      {amounts.fourthAmount > 0 && (
        <div
          className="live-amounts__item"
          onClick={() => props.onSelect(amounts.fourthAmount)}
        >{`${
          isNaN(amounts.fourthAmount / 100)
            ? "-"
            : (amounts.fourthAmount / 100).toLocaleString(undefined, {
                maximumFractionDigits: 0,
              })
        } ${
          getSymbolFromCurrency(liveCtx.liveShow.pledgesCurrency) || "€"
        }`}</div>
      )}
    </div>
  );
};

export default LiveAmounts;
