import { IonImg } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import LiveContext from "../../../contexts/live.ctx";
import { Project } from "../../../types";
import BaseButton from "../../@Shared/BaseButton/BaseButton";
import "./LiveProjectSelector.css";

interface ContainerProps {
  onDismiss: Function;
  pendingPledgeProjectId: string;
  goToPledgeConfirmation: (selectedProjectId: string) => void;
}

const LiveProjectSelector: React.FC<ContainerProps> = (props) => {
  const liveCtx = useContext(LiveContext);
  const [selectedProject, setSelectedProject] = useState("");
  const [presetProject, setPresetProject] = useState({} as Project);

  useEffect(() => {
    if (props.pendingPledgeProjectId) {
      const preset = liveCtx.liveShowProjects?.find(
        (project) => project.id == props.pendingPledgeProjectId
      );
      if (preset) {
        setPresetProject(preset);
        setSelectedProject(preset.id);
      }
    }
  }, []);

  return (
    <div className="live-project-selector" data-testid="live-project-selector">
      <div className="live-project-selector__title">
        Je fais une promesse de don pour :
      </div>
      <div className="live-project-selector__projects-list">
        {presetProject.id && (
          <div
            className={
              selectedProject == presetProject.id
                ? "live-project-selector__projects-list__item live-project-selector__projects-list__item--active"
                : "live-project-selector__projects-list__item"
            }
            onClick={() => setSelectedProject(presetProject.id)}
            key={presetProject.id}
          >
            <div className="live-project-selector__projects-list__item__logo">
              <IonImg src={presetProject.logoUrl} />
            </div>
            <span>{presetProject.name}</span>
          </div>
        )}
        {[...liveCtx.liveShowProjects]
          .sort((a, b) => (a.position > b.position ? 1 : -1))
          ?.map((project) => {
            if (project.id != presetProject.id) {
              return (
                <div
                  className={
                    selectedProject == project.id
                      ? "live-project-selector__projects-list__item live-project-selector__projects-list__item--active"
                      : "live-project-selector__projects-list__item"
                  }
                  onClick={() => setSelectedProject(project.id)}
                  key={project.id}
                >
                  <div className="live-project-selector__projects-list__item__logo">
                    <IonImg src={project.logoUrl} />
                  </div>
                  <span>{project.name}</span>
                </div>
              );
            }
          })}
      </div>
      <div className="live-project-selector__actions">
        <BaseButton outline tight onClick={props.onDismiss}>
          Annuler
        </BaseButton>
        <BaseButton
          disabled={!!!selectedProject}
          tight
          margins={"0 0 0 16px"}
          onClick={() => props.goToPledgeConfirmation(selectedProject)}
        >
          <span style={{ color: "white" }}>Suivant</span>
        </BaseButton>
      </div>
    </div>
  );
};

export default LiveProjectSelector;
