import { useContext, useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import LiveContext from "../../../contexts/live.ctx";
import "./LiveNoStreamProgression.css";

interface ContainerProps {}

const LiveNoStreamProgression: React.FC<ContainerProps> = (props) => {
  const liveCtx = useContext(LiveContext);
  const [animatePercentage, setAnimatePercentage] = useState(false);
  const [currentPercentage, setCurrentPercentage] = useState(0);

  useEffect(() => {
    const calcPercentage = Math.floor(
      ((liveCtx.liveProject.totalPledgesAmount || 0) /
        (liveCtx.liveProject.goal || 0)) *
        100
    );
    if (calcPercentage != currentPercentage) {
      setCurrentPercentage(calcPercentage);
    }
  }, [liveCtx.liveProject.totalPledgesAmount]);

  useEffect(() => {
    setAnimatePercentage(true);
    const timeout = setTimeout(() => {
      setAnimatePercentage(false);
    }, 150);
    return () => clearTimeout(timeout);
  }, [currentPercentage]);

  return (
    <div
      className="live-no-stream-progression"
      data-testid="live-no-stream-progression"
    >
      {(liveCtx.liveShow.currentProjectId ||
        liveCtx.liveShow.hasNoProjects) && (
        <div
          className={
            !animatePercentage
              ? "live-no-stream-progression__amount-progress__item"
              : "live-no-stream-progression__amount-progress__item live-no-stream-progression__amount-progress__item--animate-on"
          }
        >
          <CircularProgressbar
            value={currentPercentage}
            text={`${!isNaN(currentPercentage) ? currentPercentage : "0"}%`}
            styles={{
              path: {
                stroke: currentPercentage >= 100 ? `#2ca795` : `#848aae`,
                strokeLinecap: "butt",
                transition: "stroke-dashoffset 0.5s ease 0s",
              },
              trail: {
                stroke: "#222428",
                strokeLinecap: "butt",
              },
              text: {
                fill: currentPercentage >= 100 ? `#2ca795` : `#848aae`,
                fontSize: "24px",
                fontWeight: "bold",
              },
            }}
          />
        </div>
      )}
    </div>
  );
};

export default LiveNoStreamProgression;
