import { BottomPanelMode } from "../../../pages/Live/LivePage";
import LiveChat from "../LiveChat/LiveChat";
import LivePledgeConfirmator from "../LivePledgeConfirmator/LivePledgeConfirmator";
import LiveProjectSelector from "../LiveProjectSelector/LiveProjectSelector";
import "./LiveBottomPanel.css";
import { useState } from "react";

interface ContainerProps {
  mode: BottomPanelMode;
  pendingPledge: number;
  pendingPledgeProjectId: string;
  backToChat: Function;
  goToPledgeConfirmation: (selectedProjectId: string) => void;
}

const LiveBottomPanel: React.FC<ContainerProps> = (props) => {
  const [willReturnToChat, setWillReturnToChat] = useState(false);
  return (
    <div
      className={
        props.mode == BottomPanelMode.CHAT || willReturnToChat
          ? "live-bottom-panel"
          : "live-bottom-panel live-bottom-panel--pledge-mode"
      }
      data-testid="live-bottom-panel"
    >
      {props.mode == BottomPanelMode.CHAT ? (
        <LiveChat />
      ) : props.mode == BottomPanelMode.PROJECT_SELECTION ? (
        <LiveProjectSelector
          onDismiss={() => {
            setWillReturnToChat(true);
            setTimeout(() => {
              setWillReturnToChat(false);
              return props.backToChat();
            }, 150);
          }}
          pendingPledgeProjectId={props.pendingPledgeProjectId}
          goToPledgeConfirmation={props.goToPledgeConfirmation}
        />
      ) : (
        <LivePledgeConfirmator
          mode={props.mode}
          pendingPledgeValue={props.pendingPledge}
          pendingPledgeProjectId={props.pendingPledgeProjectId}
          onDismiss={() => {
            setWillReturnToChat(true);
            setTimeout(() => {
              setWillReturnToChat(false);
              return props.backToChat();
            }, 150);
          }}
        />
      )}
    </div>
  );
};

export default LiveBottomPanel;
