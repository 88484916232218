import { useContext, useState } from "react";
import Pledge from "../../../models/Pledge";
import { BottomPanelMode } from "../../../pages/Live/LivePage";
import BaseButton from "../../@Shared/BaseButton/BaseButton";
import BaseTextInput from "../../@Shared/BaseTextInput/BaseTextInput";
import BaseToggle from "../../@Shared/BaseToggle/BaseToggle";
import { v4 as uuidv4 } from "uuid";
import "./LivePledgeConfirmator.css";
import LiveContext from "../../../contexts/live.ctx";
import API from "../../../services";
import { useIonAlert } from "@ionic/react";
import getSymbolFromCurrency from "../../../helpers/getSymbolFromCurrency";

interface ContainerProps {
  mode: BottomPanelMode;
  pendingPledgeValue: number;
  pendingPledgeProjectId: string;
  onDismiss: Function;
}

const LivePledgeConfirmator: React.FC<ContainerProps> = (props) => {
  const liveCtx = useContext(LiveContext);
  const [isPledgePublic, setIsPledgePublic] = useState(true);
  const [freePledgeAmount, setFreePledgeAmount] = useState("");
  const [publicMessage, setPublicMessage] = useState("");
  const [pledgeIsConfirming, setPledgeIsConfirming] = useState(false);
  const [present] = useIonAlert();

  const confirmPledge = () => {
    setPledgeIsConfirming(true);
    const pledgeId = uuidv4();
    const amount =
      props.mode == BottomPanelMode.FREE_PLEDGE
        ? +freePledgeAmount
        : props.pendingPledgeValue / 100;
    // const pledge = new Pledge(
    //   pledgeId,
    //   +amount,
    //   props.pendingPledgeProjectId,
    //   null,
    //   liveCtx.liveUserData.email,
    //   null, // Might need to review phone integration
    //   liveCtx.liveUserData.payload,
    //   liveCtx.liveUserData.allowShare,
    //   liveCtx.liveShow.id
    // );
    const pledge = new Pledge(
      pledgeId,
      +amount,
      props.pendingPledgeProjectId,
      liveCtx.liveUserData.payload,
      liveCtx.liveUserData.email,
      new Date(),
      false
    );
    liveCtx
      .liveUserPledgeHandler(
        pledge,
        isPledgePublic
          ? {
              showId: liveCtx.liveShow.id,
              userData: liveCtx.liveUserData,
              messageContent: publicMessage,
            }
          : undefined
      )
      .then(() => {
        const message = `Merci pour votre promesse de ${
          props.mode == BottomPanelMode.FREE_PLEDGE
            ? +freePledgeAmount
            : props.pendingPledgeValue / 100
        }€${
          liveCtx.liveUserData.infos.firstname &&
          liveCtx.liveUserData.infos.lastname
            ? " " +
              liveCtx.liveUserData.infos.firstname +
              " " +
              liveCtx.liveUserData.infos.lastname +
              "."
            : "."
        }`;
        present({
          header: "Promesse enregistrée !",
          message: message,
          buttons: [
            {
              text: "Ok",
              handler: (d) => {
                setPledgeIsConfirming(false);
                props.onDismiss();
              },
            },
          ],
        });
      })
      .catch(() => {
        setPledgeIsConfirming(false);
        alert("An error occured");
      });
  };

  return (
    <div
      className="live-pledge-confirmator"
      data-testid="live-pledge-confirmator"
    >
      {props.mode == BottomPanelMode.PLEDGE ? (
        <div className="live-pledge-confirmator__amount-confirmation">
          <span>Confirmer ma promesse :</span>
          <span>{`${(props.pendingPledgeValue / 100).toLocaleString()} ${
            getSymbolFromCurrency(liveCtx.liveShow.pledgesCurrency) || "€"
          }`}</span>
        </div>
      ) : (
        <div className="live-pledge-confirmator__free-amount">
          <span className="live-pledge-confirmator__free-amount__label">
            Montant libre :
          </span>
          <span className="live-pledge-confirmator__free-amount__amount--desktop">{`${+freePledgeAmount.toLocaleString()} ${
            getSymbolFromCurrency(liveCtx.liveShow.pledgesCurrency) || "€"
          }`}</span>
          <div className="live-pledge-confirmator__free-amount__input-container">
            <BaseTextInput
              type="number"
              placeholder="Ma promesse..."
              controller={{
                value: freePledgeAmount,
                onChange: (event: any) =>
                  setFreePledgeAmount(event.target.value),
              }}
            />
          </div>
        </div>
      )}

      <div className="live-pledge-confirmator__public-pledge-asker__message">
        <BaseTextInput
          placeholder="Mon message (optionnel)..."
          disabled={!isPledgePublic}
          controller={{
            value: publicMessage,
            onChange: (event: any) => setPublicMessage(event.target.value),
          }}
        />
      </div>
      <div className="live-pledge-confirmator__public-pledge-asker">
        <div className="live-pledge-confirmator__public-pledge-asker__toggle">
          <BaseToggle
            isActive={isPledgePublic}
            onClick={() => setIsPledgePublic(!isPledgePublic)}
          />
        </div>
        <span className="live-pledge-confirmator__public-pledge-asker__toggle-label">
          Je souhaite que ma promesse de don s'affiche dans le chat et soit
          visible par les autres participants.
        </span>
      </div>
      <div className="live-pledge-confirmator__actions">
        <BaseButton outline tight onClick={props.onDismiss}>
          Annuler
        </BaseButton>
        <BaseButton
          onClick={confirmPledge}
          tight
          margins={"0 0 0 16px"}
          isLoading={pledgeIsConfirming}
          disabled={
            props.mode == BottomPanelMode.FREE_PLEDGE
              ? freePledgeAmount == ""
              : false
          }
        >
          <span style={{ color: "white" }}>Confirmer</span>
        </BaseButton>
      </div>
    </div>
  );
};

export default LivePledgeConfirmator;
