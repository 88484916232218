export type InputSchema = {
  id: string;
  name: string;
  required: boolean;
  type: "TEXT" | "NUMBER" | "CHECKBOX";
};

export default class Show {
  public id;
  public isPublished;
  public isOnPlace;
  public isOnRemote;
  public name;
  public place;
  public subtitle;
  public urlSlug;
  public startDate;
  public endDate;
  public description;
  public endMessage;
  public endButtonText;
  public endButtonUrl;
  public comingButtonText;
  public comingButtonUrl;
  public pastButtonText;
  public pastButtonUrl;
  public coverUrl;
  public logoUrl;
  public isStreamingEnabled;
  public youtubeStreamId;
  public hasPledgesPresets;
  public firstPledgeAmount;
  public secondPledgeAmount;
  public thirdPledgeAmount;
  public fourthPledgeAmount;
  public pledgesCurrency;
  public hasFreePledgeAmount;
  public hasTaxReceiptAvailable;
  public hasCustomPledgeAmounts;
  public isFirstnamePresetOn;
  public isFirstnamePresetRequired;
  public isLastnamePresetOn;
  public isLastnamePresetRequired;
  public isPhonePresetOn;
  public isPhonePresetRequired;
  public isAddressFirstLinePresetOn;
  public isAddressFirstLinePresetRequired;
  public isAddressSecondLinePresetOn;
  public isAddressSecondLinePresetRequired;
  public isZipcodePresetOn;
  public isZipcodePresetRequired;
  public isCityPresetOn;
  public isCityPresetRequired;
  public isCountryPresetOn;
  public isCountryPresetRequired;
  public customRegistrationsList;
  public currentProjectId;
  public isClosed;
  public totalPledgesAmount;
  public hasNoProjects;
  public customPledgeAmountsCount;
  public averagePledgesAmount;
  public allPledgesCount;
  public totalUsersCount;
  public forcedEndingAmount;
  public globalShowGoal;
  public isLnbc;
  public backgroundColor;
  public fontColor;
  public colorPledge;
  public backgroundColorPledge;
  public backgroundColorPledgeBeyond;
  public backgroundColorLogo;
  public isPseudoPresetOn;
  public isPseudoPresetRequired;
  public multiplexId?;

  constructor(
    id: string,
    name: string,
    urlSlug: string,
    isPublished: boolean = false,
    isOnPlace: boolean = false,
    isOnRemote: boolean = false,
    place: string = "",
    subtitle: string = "",
    startDate: Date = new Date(),
    endDate: Date = new Date(),
    description: string = "",
    endMessage: string = "",
    endButtonText: string = "",
    endButtonUrl: string = "",
    comingButtonText: string = "",
    comingButtonUrl: string = "",
    pastButtonText: string = "",
    pastButtonUrl: string = "",
    coverUrl: string = "",
    logoUrl: string = "",
    isStreamingEnabled: boolean = false,
    youtubeStreamId: string = "",
    hasPledgesPresets: boolean = false,
    firstPledgeAmount: number = 0,
    secondPledgeAmount: number = 0,
    thirdPledgeAmount: number = 0,
    fourthPledgeAmount: number = 0,
    pledgesCurrency: string = "EUR",
    hasFreePledgeAmount: boolean = false,
    hasTaxReceiptAvailable: boolean = false,
    hasCustomPledgeAmounts: boolean = false,
    isFirstnamePresetOn: boolean = false,
    isFirstnamePresetRequired: boolean = false,
    isLastnamePresetOn: boolean = false,
    isLastnamePresetRequired: boolean = false,
    isPhonePresetOn: boolean = false,
    isPhonePresetRequired: boolean = false,
    isAddressFirstLinePresetOn: boolean = false,
    isAddressFirstLinePresetRequired: boolean = false,
    isAddressSecondLinePresetOn: boolean = false,
    isAddressSecondLinePresetRequired: boolean = false,
    isZipcodePresetOn: boolean = false,
    isZipcodePresetRequired: boolean = false,
    isCityPresetOn: boolean = false,
    isCityPresetRequired: boolean = false,
    isCountryPresetOn: boolean = false,
    isCountryPresetRequired: boolean = false,
    customRegistrationsList: InputSchema[] = [],
    currentProjectId: string = "",
    isClosed: boolean = false,
    totalPledgesAmount: number = 0,
    hasNoProjects: boolean = false,
    customPledgeAmountsCount: number = 0,
    averagePledgesAmount: number = 0,
    allPledgesCount: number = 0,
    totalUsersCount: number = 0,
    forcedEndingAmount: number = 0,
    globalShowGoal: number = 0,
    isLnbc: boolean = false,
    backgroundColor: string = "",
    fontColor: string = "",
    colorPledge: string = "",
    backgroundColorPledge: string = "",
    backgroundColorPledgeBeyond: string = "",
    backgroundColorLogo: string = "",
    isPseudoPresetOn: boolean = false,
    isPseudoPresetRequired: boolean = false,
    multiplexId?: string
  ) {
    this.id = id;
    this.isPublished = isPublished;
    this.isOnPlace = isOnPlace;
    this.isOnRemote = isOnRemote;
    this.name = name;
    this.place = place;
    this.subtitle = subtitle;
    this.urlSlug = urlSlug;
    this.startDate = startDate;
    this.endDate = endDate;
    this.description = description;
    this.endMessage = endMessage;
    this.endButtonText = endButtonText;
    this.endButtonUrl = endButtonUrl;
    this.comingButtonText = comingButtonText;
    this.comingButtonUrl = comingButtonUrl;
    this.pastButtonText = pastButtonText;
    this.pastButtonUrl = pastButtonUrl;
    this.coverUrl = coverUrl;
    this.logoUrl = logoUrl;
    this.isStreamingEnabled = isStreamingEnabled;
    this.youtubeStreamId = youtubeStreamId;
    this.hasPledgesPresets = hasPledgesPresets;
    this.firstPledgeAmount = firstPledgeAmount;
    this.secondPledgeAmount = secondPledgeAmount;
    this.thirdPledgeAmount = thirdPledgeAmount;
    this.fourthPledgeAmount = fourthPledgeAmount;
    this.pledgesCurrency = pledgesCurrency;
    this.hasFreePledgeAmount = hasFreePledgeAmount;
    this.hasTaxReceiptAvailable = hasTaxReceiptAvailable;
    this.hasCustomPledgeAmounts = hasCustomPledgeAmounts;
    this.isFirstnamePresetOn = isFirstnamePresetOn;
    this.isFirstnamePresetRequired = isFirstnamePresetRequired;
    this.isLastnamePresetOn = isLastnamePresetOn;
    this.isLastnamePresetRequired = isLastnamePresetRequired;
    this.isPhonePresetOn = isPhonePresetOn;
    this.isPhonePresetRequired = isPhonePresetRequired;
    this.isAddressFirstLinePresetOn = isAddressFirstLinePresetOn;
    this.isAddressFirstLinePresetRequired = isAddressFirstLinePresetRequired;
    this.isAddressSecondLinePresetOn = isAddressSecondLinePresetOn;
    this.isAddressSecondLinePresetRequired = isAddressSecondLinePresetRequired;
    this.isZipcodePresetOn = isZipcodePresetOn;
    this.isZipcodePresetRequired = isZipcodePresetRequired;
    this.isCityPresetOn = isCityPresetOn;
    this.isCityPresetRequired = isCityPresetRequired;
    this.isCountryPresetOn = isCountryPresetOn;
    this.isCountryPresetRequired = isCountryPresetRequired;
    this.customRegistrationsList = customRegistrationsList;
    this.currentProjectId = currentProjectId;
    this.isClosed = isClosed;
    this.totalPledgesAmount = totalPledgesAmount;
    this.hasNoProjects = hasNoProjects;
    this.customPledgeAmountsCount = customPledgeAmountsCount;
    this.averagePledgesAmount = averagePledgesAmount;
    this.allPledgesCount = allPledgesCount;
    this.totalUsersCount = totalUsersCount;
    this.forcedEndingAmount = forcedEndingAmount;
    this.globalShowGoal = globalShowGoal;
    this.isLnbc = isLnbc;
    this.backgroundColor = backgroundColor;
    this.fontColor = fontColor;
    this.colorPledge = colorPledge;
    this.backgroundColorPledge = backgroundColorPledge;
    this.backgroundColorPledgeBeyond = backgroundColorPledgeBeyond;
    this.backgroundColorLogo = backgroundColorLogo;
    this.isPseudoPresetOn = isPseudoPresetOn;
    this.isPseudoPresetRequired = isPseudoPresetRequired;
    this.multiplexId = multiplexId;
  }
}
