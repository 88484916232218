import "./MultiplexJauge.css"
import {IonContent, IonPage} from "@ionic/react";
import BottomOverlay from "../../components/Multiplex/BottomOverlay/BottomOverlay";
import DashedProgressBar from "../../components/Multiplex/DashedProgressBar/DashedProgressBar";
import {useContext, useEffect} from "react";
import LiveContext from "../../contexts/live.ctx";
import {RouteComponentProps} from "react-router";
import API from "../../services";
import Show from "../../models/Show";
import Multiplex from "../../models/Multiplex";
import getSymbolFromCurrency from "../../helpers/getSymbolFromCurrency";
import formatedNumbers from "../../helpers/formatedNumbersWithDot";

interface CounterPageProps
  extends RouteComponentProps<{
    slug: string;
  }> {}

const MultiplexJauge: React.FC<CounterPageProps> = (props) => {
  const liveCtx = useContext(LiveContext);

  useEffect(() => {
    async function fetchShowBySlug() {
      API.getShowBySlug(props.match.params.slug)
        .then((show: Show) => {
          liveCtx.initLiveData(show.id);
        })
        .catch((err) => {
          return console.log(err);
        });
    }
    if (!liveCtx.liveShow.id) {
      fetchShowBySlug();
    }
  }, [liveCtx.liveShow.id]);

  useEffect(() => {
    if (liveCtx.liveShow.multiplexId) {
      API.getMultiplexBySlug(liveCtx.liveShow.multiplexId)
        .then((multiplex: Multiplex) => {
          liveCtx.initMultiplex(multiplex);
        })
        .catch((err) => {
          return console.log(err);
        });
    }
  }, [
    liveCtx.liveShow.multiplexId
  ]);

  useEffect(() => {
    if (liveCtx.liveMultiplex.currentDashboardId) {
      const currentDashboard = liveCtx.liveMultiplexDashboards.find(
        dashboard => dashboard.id === liveCtx.liveMultiplex.currentDashboardId)
      if (currentDashboard) liveCtx.liveDashboardHandler(currentDashboard)
    }
  }, [
    liveCtx.liveMultiplex.currentDashboardId,
    liveCtx.liveMultiplexDashboards
  ]);

  return(
    <IonPage className="multiplex-jauge" data-testid="multiplex-jauge">
      <IonContent>
        <main
          className="multiplex-jauge__main"
          style={{
            color: liveCtx.liveMultiplex.fontColor
          }}
        >
          <div className="multiplex-jauge__flux" />

          <BottomOverlay
            lightColor={liveCtx.liveMultiplex.backgroundColorLight}
            darkColor={liveCtx.liveMultiplex.backgroundColorDark}
            left={
              <div className="multiplex-jauge__title">
                {(liveCtx.liveDashboard.logoUrl && liveCtx.liveDashboard.logoUrl !== "") ?
                  <img
                    src={liveCtx.liveDashboard.logoUrl}
                    alt="logo"
                  />
                  :
                  <p>{liveCtx.liveDashboard.name}</p>
                }
              </div>
            }
            middle={
              <div className="multiplex-jauge__progress-bar">
                <DashedProgressBar
                  colorBase={liveCtx.liveMultiplex.fontColor}
                  colorHighlight={liveCtx.liveMultiplex.fontColorHighlight}
                  count={liveCtx.liveDashboard.totalPledgesAmount}
                  objective={liveCtx.liveDashboard.goal}
                />
              </div>
            }
            right={
              <div className="multiplex-jauge__counter">
                <div
                  className="multiplex-jauge__counter-label"
                  style={{
                    color: liveCtx.liveMultiplex.fontColorHighlight
                  }}
                >
                  Levée totale du projet
                </div>
                <div className="multiplex-jauge__counter-state">
                  {formatedNumbers(liveCtx.liveDashboard.totalPledgesAmount / 100)}
                  {getSymbolFromCurrency(liveCtx.liveShow.pledgesCurrency)}
                </div>
                <div className="multiplex-jauge__counter-goal">
                  sur un objectif de
                  <span> {formatedNumbers(liveCtx.liveDashboard.goal / 100)} </span>
                  {getSymbolFromCurrency(liveCtx.liveShow.pledgesCurrency)}
                </div>
              </div>
            }
          />

        </main>
      </IonContent>
    </IonPage>
  )
}

export default MultiplexJauge