export default class UserData {
  public email;
  public infos;
  public onPlace;
  public askReceipt;
  public eventId;
  public pannelNumber;
  public allowShare;
  public customAmounts;
  public showSlug;
  public pseudo;

  constructor(
    email: string,
    infos: Record<any, any>,
    onPlace: boolean,
    askReceipt: boolean,
    eventId: string,
    pannelNumber: string,
    allowShare: boolean,
    showSlug: string,
    customAmounts?: {
      firstAmount: number;
      secondAmount: number;
      thirdAmount: number;
      fourthAmount: number;
      freeAmount: boolean;
    } | null,
    pseudo?: string
  ) {
    this.email = email;
    this.infos = infos;
    this.onPlace = onPlace;
    this.askReceipt = askReceipt;
    this.eventId = eventId;
    this.pannelNumber = pannelNumber || "0000";
    this.allowShare = allowShare || false;
    this.showSlug = showSlug;
    this.customAmounts = customAmounts;
    this.pseudo = pseudo;
  }

  hydrate(payload: {
    email: string;
    infos: Record<any, any>;
    onPlace: boolean;
    askReceipt: boolean;
    eventId: string;
    pannelNumber: string;
    allowShare: boolean;
    showSlug: string;
    customAmounts?: {
      firstAmount: number;
      secondAmount: number;
      thirdAmount: number;
      fourthAmount: number;
      freeAmount: boolean;
    } | null;
    pseudo?: string;
  }) {
    this.email = payload.email;
    this.infos = payload.infos;
    this.onPlace = payload.onPlace;
    this.askReceipt = payload.askReceipt;
    this.eventId = payload.eventId;
    this.pannelNumber = payload.pannelNumber || "0000";
    this.allowShare = payload.allowShare;
    this.showSlug = payload.showSlug;
    this.customAmounts = payload.customAmounts;
    this.pseudo = payload.pseudo;
  }

  get payload() {
    return {
      email: this.email,
      infos: this.infos,
      onPlace: this.onPlace,
      askReceipt: this.askReceipt,
      eventId: this.eventId,
      pannelNumber: this.pannelNumber || "0000",
      allowShare: this.allowShare,
      showSlug: this.showSlug,
      customAmounts: this.customAmounts,
      pseudo: this.pseudo
    };
  }

  get isAdmin() {
    return this.infos?.firstname?.split("?")[0] == "admin";
  }

  get liveHashId() {
    if (this.isAdmin) {
      return this.infos?.firstname?.split("?")[1];
    } else {
      const hashMail = this.email
        .split("")
        .reduce(function (a, b) {
          a = (a << 5) - a + b.charCodeAt(0);
          return a & a;
        }, 0)
        .toString();
      return (
        (this.email[0] || "f") +
        (this.email[1] || "o") +
        (this.email[2] || "o") +
        (hashMail[1] || "0") +
        (hashMail[2] || "0") +
        (hashMail[3] || "0")
      );
    }
  }
}
