import "./BottomOverlay.css"

interface BottomOverlayProps {
  lightColor: string
  darkColor: string
  left: JSX.Element | JSX.Element[]
  middle: JSX.Element | JSX.Element[]
  right: JSX.Element | JSX.Element[]
}
const BottomOverlay = (props: BottomOverlayProps) => {
  return (
    <div className="bottom-overlay">
      <div className="bottom-overlay__container">
        <div
          className="bottom-overlay__background"
          style={{
            background: `radial-gradient(circle at 64% 50%, ${props.lightColor} 0%, ${props.darkColor} 90%)`
          }}
        />
        <div className="bottom-overlay__inner-container">
          <div className="bottom-overlay__left">
            {props.left}
          </div>
          <div className="bottom-overlay__middle">
            {props.middle}
          </div>
          <div className="bottom-overlay__right">
            {props.right}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BottomOverlay