import React, { useEffect, useState } from "react";
import Show from "../models/Show";
import services from "../services";
import dayjs from "dayjs";

type HomeContextProps = {
  isLoading: boolean;
  allTodayShows: Show[];
  allComingShows: Show[];
  allPastShows: Show[];
  currentSegment: string;
  currentSegmentSelectionHandler: Function;
};

const HomeContext = React.createContext<HomeContextProps>({
  isLoading: false,
  allTodayShows: [],
  allComingShows: [],
  allPastShows: [],
  currentSegment: "TODAY",
  currentSegmentSelectionHandler: () => {},
});

export const HomeContextProvider: React.FC = (props) => {
  const [isFetching, setIsFetching] = useState(false);
  const [allTodayShows, setAllTodayShows] = useState<Show[]>([]);
  const [allComingShows, setAllComingShows] = useState<Show[]>([]);
  const [allPastShows, setAllPastShows] = useState<Show[]>([]);
  const [currentSegment, setCurrentSegment] = useState("TODAY");

  useEffect(() => {
    setIsFetching(true);
    const allShowsListener: Function = services.listenToAllShows(
      (shows: Show[]) => {
        setAllTodayShows(
          [...shows]
            .filter((sh) => {
              return (
                (dayjs(sh.startDate).isBefore(dayjs()) ||
                  dayjs(sh.startDate).isSame(dayjs(), "day")) &&
                !sh.isClosed
              );
            })
            .sort((a, b) => {
              return b.startDate.getTime() - a.startDate.getTime();
            })
        );
        setAllComingShows(
          [...shows]
            .filter(
              (sh) =>
                dayjs(sh.startDate).isAfter(dayjs()) &&
                dayjs().date() != dayjs(sh.startDate).date()
            )
            .sort((a, b) => {
              return b.startDate.getTime() - a.startDate.getTime();
            })
        );
        setAllPastShows(
          [...shows]
            .filter((sh) => sh.isClosed)
            .sort((a, b) => {
              return b.startDate.getTime() - a.startDate.getTime();
            })
        );
        setIsFetching(false);
      }
    );
    return () => allShowsListener();
  }, []);

  const currentSegmentSelectionHandler = (segment: string) => {
    setCurrentSegment(segment);
  };

  return (
    <HomeContext.Provider
      value={{
        isLoading: isFetching,
        allTodayShows,
        allComingShows,
        allPastShows,
        currentSegment,
        currentSegmentSelectionHandler,
      }}
    >
      {props.children}
    </HomeContext.Provider>
  );
};

export default HomeContext;
