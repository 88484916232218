import "./DashedProgressBar.css"
import handIcon from "../../../assets/icon/Main.png"
import heartIcon from "../../../assets/icon/Coeur.png"
import {IonImg} from "@ionic/react";

interface DashedProgressBarProps {
  colorBase: string
  colorHighlight: string
  count: number,
  objective: number
}

const DashedProgressBar = (props: DashedProgressBarProps) => {
  const progress =
    Math.round(props.count * 100 / props.objective)
  const getContrastYIQ = (hexcolor: string) => {
    const hex = hexcolor.substring(1);
    const rgb = parseInt(hex, 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >>  8) & 0xff;
    const b = (rgb >>  0) & 0xff;

    const contrast = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    return contrast < 150 ? hexcolor : 'black'
  }

  return (
    <div className="dashed-bar__container">
      <div className="dashed-bar__icon">
        <IonImg src={handIcon} />
      </div>

      <div className="dashed-bar__bar">
        {props.objective !== 0 ? (
          <div className="dashed-bar__bar-legend">
            <div
              style={{
                left: progress < 100 ? `${progress}%` : `100%`,
                color: props.colorHighlight ? getContrastYIQ(props.colorHighlight) : 'black',
              }}
              className="dashed-bar__bar-legend__current"
            >{progress ? progress + "%" : "0%"}</div>
            <div
              style={{
                left: progress < 100 ? `${progress}%` : `100%`,
              }}
              className="dashed-bar__bar-legend__current-pointer"
            />
          </div>
        ) : (
          <div className="dashed-bar__bar-legend">
            <div
              style={{
                left: `100%`,
                color: props.colorHighlight,
                backgroundColor: props.colorBase
              }}
              className="dashed-bar__bar-legend__current"
            >{"100%"}</div>
            <div
              style={{
                left: `100%`,
                borderTopColor: props.colorBase
              }}
              className="dashed-bar__bar-legend__current-pointer"
            />
          </div>
        )}
        <div className="dashed-bar__bar-container">
          <div className="dashed-bar__bar-filled"
               style={{
                 width: `${progress}%`,
                 maxWidth: "100%"
               }}
          />
        </div>
      </div>

      <div className="dashed-bar__icon">
        <img src={heartIcon} />
      </div>
    </div>
  )
}

export default DashedProgressBar