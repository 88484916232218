import { useContext } from "react";
import LiveContext from "../../../contexts/live.ctx";
import "./LiveStream.css";

interface ContainerProps {}

const LiveStream: React.FC<ContainerProps> = (props) => {
  const src = "https://www.youtube.com/embed/5qap5aO4i9A";
  const liveCtx = useContext(LiveContext);
  return (
    <div className="live-stream" data-testid="live-stream">
      <iframe
        className="live-stream__youtube-video"
        width="100%"
        src={`https://www.youtube.com/embed/${liveCtx.liveShow.youtubeStreamId}?playsinline=1`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      ></iframe>
    </div>
  );
};

export default LiveStream;
