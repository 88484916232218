import { IonHeader, IonToolbar } from "@ionic/react";
import { useHistory } from "react-router";
import BaseButton from "../../@Shared/BaseButton/BaseButton";
import BaseLogo from "../../@Shared/BaseLogo/BaseLogo";
import "./HomeHeader.css";

interface ContainerProps {}

const HomeHeader: React.FC<ContainerProps> = (props) => {
  const history = useHistory();

  return (
    <IonHeader className="home-header" data-testid="home-header">
      <IonToolbar>
        <div className="home-header__content">
          <BaseLogo action={() => history.push("/")} />
          <div />
          {/* <BaseButton tight outline>
            DEMO
          </BaseButton> */}
        </div>
      </IonToolbar>
    </IonHeader>
  );
};

export default HomeHeader;
