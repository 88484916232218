import {
  FIELD_REQUIRED,
  EMAIL_FORMAT_REQUIRED,
  MIN_LENGTH,
} from "../../messages/error";
import { InputSchema } from "../../models/Show";

export const emailRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export default (inputSchema: InputSchema) => {
  let rules = {};
  if (inputSchema.required) {
    rules = {
      required: FIELD_REQUIRED,
    };
  }
  // if (inputSchema.type == "email") {
  //   rules = {
  //     ...rules,
  //     pattern: { value: emailRegex, message: EMAIL_FORMAT_REQUIRED },
  //   };
  // }
  // if (inputSchema.type == "password") {
  //   rules = {
  //     ...rules,
  //     minLength: {
  //       value: 6,
  //       message: MIN_LENGTH(6),
  //     },
  //   };
  // }
  return rules;
};
