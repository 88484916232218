import { IonImg } from "@ionic/react";
import defaultLogo from "../../../assets/logo/default.png";
import "./BaseLogo.css";

interface ContainerProps {
  action?: Function;
}

const BaseLogo: React.FC<ContainerProps> = (props) => {
  return (
    <div
      className="base-logo"
      data-testid="base-logo"
      onClick={(event) => (props.action ? props.action(event) : null)}
    >
      <IonImg src={defaultLogo} />
    </div>
  );
};

export default BaseLogo;
