import { IonContent, IonPage } from "@ionic/react";
import {RouteComponentProps} from "react-router";
import {useContext, useEffect, useState} from "react";
import LiveContext from "../../contexts/live.ctx";
import "./MultiplexEndScreen.css";
import API from "../../services";
import Show from "../../models/Show";
import Multiplex from "../../models/Multiplex";
import { ReactComponent as Heart } from "../../assets/icon/HeartShape.svg"
import Counter from "../../components/Multiplex/Counter/Counter";
import ContainerWithGradient from "../../components/Multiplex/ContainerWithGradient/ContainerWithGradient";

interface CounterPageProps
  extends RouteComponentProps<{
    slug: string;
  }> {}

const MultiplexEndScreenPage: React.FC<CounterPageProps> = (props) => {
  const liveCtx = useContext(LiveContext);
  const [eventList, setEventList] = useState([] as Show[])
  console.log(eventList)

  useEffect(() => {
    async function fetchShowBySlug() {
      API.getShowBySlug(props.match.params.slug)
        .then((show: Show) => {
          liveCtx.initLiveData(show.id);
        })
        .catch((err) => {
          return console.log(err);
        });
    }
    if (!liveCtx.liveShow.id) {
      fetchShowBySlug();
    }
  }, [liveCtx.liveShow.id]);

  useEffect(() => {
    if (liveCtx.liveShow.multiplexId) {
      API.getMultiplexBySlug(liveCtx.liveShow.multiplexId)
        .then((multiplex: Multiplex) => {
          liveCtx.initMultiplex(multiplex);
        })
        .catch((err) => {
          return console.log(err);
        });
    }
  }, [
    liveCtx.liveShow.multiplexId
  ]);

  useEffect(() => {
    if (liveCtx.liveMultiplex.participatingShows && liveCtx.liveMultiplex.participatingShows.length > 0) {
      API.getMultiplexShows(liveCtx.liveMultiplex.participatingShows)
        .then((shows: Show[]) => {
          setEventList(
            shows.sort(
              (a, b) => (a.totalPledgesAmount > b.totalPledgesAmount) ? -1 : 1));
        })
        .catch((err) => {
          return console.log(err);
        });
    }
  }, [
    liveCtx.liveMultiplex.participatingShows
  ]);

  return (
    <IonPage className="multiplex-end-page">
      <IonContent>
        <main
          className="multiplex-end__main"
          style={{
            color: liveCtx.liveMultiplex.fontColor,
            background: `radial-gradient(at 50% 130%, 
            ${liveCtx.liveMultiplex.backgroundColorLight} 9%, ${liveCtx.liveMultiplex.backgroundColorDark} 70%)`
          }}
        >
          <div className="multiplex-end__main__multiplex-card">
            <div className="multiplex-end__main__multiplex-card__infos">
              {liveCtx.liveMultiplex.logoUrl && liveCtx.liveMultiplex.logoUrl !== "" && (
                <img
                  src={liveCtx.liveMultiplex.logoUrl}
                  alt="logo"
                />
              )}
            </div>
            <h1 className="multiplex-end__main__multiplex-card__title">
              {liveCtx.liveMultiplex.name}
              {liveCtx.liveMultiplex.subtitle !== "" && liveCtx.liveMultiplex.subtitle !== "-" && (
                <span
                  className="multiplex-end__main__multiplex-card__title-span"
                  style={{
                    color: liveCtx.liveMultiplex.backgroundColorLight
                  }}
                > &bull; {liveCtx.liveMultiplex.subtitle}</span>
              )}
            </h1>
          </div>

          <div className="multiplex-end__main__multiplex-tracker">
            <div className="multiplex-end__main__multiplex-tracker__content">
              <Counter
                label="Levée totale de la soirée"
                labelColor={liveCtx.liveMultiplex.fontColorHighlight}
                count={ liveCtx.liveMultiplex.totalPledgesAmount / 100}
                hasSpecialFormat
                isBig
              />
            </div>
            <Heart
              className="multiplex-end__multiplex-tracker__heart"
              style={{ color: liveCtx.liveMultiplex.backgroundColorDark }}
            />
          </div>

          <div className="multiplex-end__show-list">
            {eventList.map(event =>
              <ContainerWithGradient key={event.id}>
                <Counter label={event.place}
                         labelColor={liveCtx.liveMultiplex.fontColorHighlight}
                         count={event.totalPledgesAmount / 100}/>
              </ContainerWithGradient>
            )}
          </div>
        </main>
      </IonContent>
    </IonPage>
  )
}

export default MultiplexEndScreenPage