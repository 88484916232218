import { IonImg } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import LiveContext from "../../../contexts/live.ctx";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./LiveBannerProject.css";
import getSymbolFromCurrency from "../../../helpers/getSymbolFromCurrency";

interface ContainerProps {}

const LiveBannerProject: React.FC<ContainerProps> = (props) => {
  const liveCtx = useContext(LiveContext);
  const [animateAmount, setAnimateAmount] = useState(false);
  const [animatePercentage, setAnimatePercentage] = useState(false);
  const [currentPercentage, setCurrentPercentage] = useState(0);

  useEffect(() => {
    const calcPercentage = Math.floor(
      ((liveCtx.liveProject.totalPledgesAmount || 0) /
        (liveCtx.liveProject.goal || 0)) *
        100
    );
    if (calcPercentage != currentPercentage) {
      setCurrentPercentage(calcPercentage);
    }
  }, [liveCtx.liveProject.totalPledgesAmount]);

  useEffect(() => {
    setAnimateAmount(true);
    const amountTimeout = setTimeout(() => {
      setAnimateAmount(false);
    }, 150);
    return () => clearTimeout(amountTimeout);
  }, [liveCtx.liveProject.totalPledgesAmount]);

  useEffect(() => {
    setAnimatePercentage(true);
    const percentageTimeout = setTimeout(() => {
      setAnimatePercentage(false);
    }, 150);
    return () => clearTimeout(percentageTimeout);
  }, [currentPercentage]);

  return (
    <div className="live-banner-project" data-testid="live-banner-project">
      <div className="live-banner-project__logo">
        <div className="live-banner-project__logo__item">
          {liveCtx.liveProject.logoUrl && (
            <IonImg src={liveCtx.liveProject.logoUrl} />
          )}
        </div>
      </div>
      <div className="live-banner-project__amount-progress">
        {liveCtx.liveShow.isStreamingEnabled ? (
          <div
            className={
              !animatePercentage
                ? "live-banner-project__amount-progress__item"
                : "live-banner-project__amount-progress__item live-banner-project__amount-progress__item--animate-on"
            }
          >
            <CircularProgressbar
              value={currentPercentage}
              text={`${currentPercentage}%`}
              styles={{
                path: {
                  stroke: currentPercentage >= 100 ? `#2ca795` : `#848aae`,
                  strokeLinecap: "butt",
                  transition: "stroke-dashoffset 0.5s ease 0s",
                },
                trail: {
                  stroke: "#222428",
                  strokeLinecap: "butt",
                },
                text: {
                  fill: currentPercentage >= 100 ? `#2ca795` : `#848aae`,
                  fontSize: "24px",
                  fontWeight: "bold",
                },
              }}
            />
          </div>
        ) : (
          <div />
        )}
      </div>
      <div className="live-banner-project__goal">
        <div className="live-banner-project__goal__display">
          <span
            className={
              animateAmount ? "animate-amount-on" : "animate-amount-off"
            }
          >
            {liveCtx.liveProject.totalPledgesAmount
              ? `${(
                  liveCtx.liveProject.totalPledgesAmount / 100
                ).toLocaleString(undefined, { maximumFractionDigits: 0 })} ${
                  getSymbolFromCurrency(liveCtx.liveShow.pledgesCurrency) || "€"
                }`
              : "0 " + getSymbolFromCurrency(liveCtx.liveShow.pledgesCurrency)}
          </span>
          <span>
            {liveCtx.liveProject.goal
              ? `Objectif : ${(liveCtx.liveProject.goal / 100).toLocaleString(
                  undefined,
                  { maximumFractionDigits: 0 }
                )} ${
                  getSymbolFromCurrency(liveCtx.liveShow.pledgesCurrency) || "€"
                }`
              : "0 " + getSymbolFromCurrency(liveCtx.liveShow.pledgesCurrency)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default LiveBannerProject;
