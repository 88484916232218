import "./MultiplexOverview.css"
import {IonContent, IonPage} from "@ionic/react";
import BottomOverlay from "../../components/Multiplex/BottomOverlay/BottomOverlay";
import Divider from "../../components/Multiplex/Divider/Divider";
import Counter from "../../components/Multiplex/Counter/Counter";
import {RouteComponentProps} from "react-router";
import {useContext, useEffect} from "react";
import LiveContext from "../../contexts/live.ctx";
import API from "../../services";
import Show from "../../models/Show";
import Multiplex from "../../models/Multiplex";
import { ReactComponent as Heart } from "../../assets/icon/HeartShape.svg"

interface CounterPageProps
  extends RouteComponentProps<{
    slug: string;
  }> {}

const MultiplexOverview: React.FC<CounterPageProps> = (props) => {
  const liveCtx = useContext(LiveContext);

  useEffect(() => {
    async function fetchShowBySlug() {
      API.getShowBySlug(props.match.params.slug)
        .then((show: Show) => {
          liveCtx.initLiveData(show.id);
        })
        .catch((err) => {
          return console.log(err);
        });
    }
    if (!liveCtx.liveShow.id) {
      fetchShowBySlug();
    }
  }, [liveCtx.liveShow.id]);

  useEffect(() => {
    if (liveCtx.liveShow.multiplexId) {
      API.getMultiplexBySlug(liveCtx.liveShow.multiplexId)
        .then((multiplex: Multiplex) => {
          liveCtx.initMultiplex(multiplex);
        })
        .catch((err) => {
          return console.log(err);
        });
    }
  }, [
    liveCtx.liveShow.multiplexId
  ]);

  useEffect(() => {
    if (liveCtx.liveMultiplex.currentDashboardId) {
      const currentDashboard = liveCtx.liveMultiplexDashboards.find(
        dashboard => dashboard.id === liveCtx.liveMultiplex.currentDashboardId)
      if (currentDashboard) liveCtx.liveDashboardHandler(currentDashboard)
    }
  }, [
    liveCtx.liveMultiplex.currentDashboardId,
    liveCtx.liveMultiplexDashboards
  ]);

  return(
    <IonPage className="multiplex-overview" data-testid="multiplex-overview">
      <IonContent>
        <main
          className="multiplex-overview__main"
          style={{
            color: liveCtx.liveMultiplex.fontColor
          }}
        >
          <div className="multiplex-overview__flux" />

          <BottomOverlay
            lightColor={liveCtx.liveMultiplex.backgroundColorLight}
            darkColor={liveCtx.liveMultiplex.backgroundColorDark}
            left={
              <div className="multiplex-overview__logo">
                {liveCtx.liveMultiplex.logoUrl && liveCtx.liveMultiplex.logoUrl !== "" && (
                  <img
                    src={liveCtx.liveMultiplex.logoUrl}
                    alt="logo"
                  />
                )}
              </div>
            }
            middle={
              <div className="multiplex-overview__counts">
                <div className="multiplex-overview__label">
                  <div className="multiplex-overview__name">
                    {liveCtx.liveMultiplex.name}
                  </div>
                  <div
                    className="multiplex-overview__subtitle"
                    style={{
                      color: liveCtx.liveMultiplex.backgroundColorLight
                    }}
                  >
                    {liveCtx.liveMultiplex.subtitle}
                  </div>
                </div>
                <Divider />
                <div className="multiplex-overview__heart-container">
                  <div className="multiplex-overview__heart-content">
                    <Counter label="Levée totale de la soirée"
                             labelColor={liveCtx.liveMultiplex.fontColorHighlight}
                             count={liveCtx.liveMultiplex.totalPledgesAmount / 100}
                             hasSpecialFormat
                             isBig
                    />
                  </div>
                  <Heart
                    className="multiplex-overview__heart"
                    style={{ color: liveCtx.liveMultiplex.backgroundColorDark }}
                  />
                </div>
              </div>
            }
            right={
              <div className="multiplex-overview__revers-gradient__container">
                <div
                  className="multiplex-overview__revers-gradient"
                  style={{
                    background: `linear-gradient(
                    90deg, ${liveCtx.liveMultiplex.backgroundColorDark}, 
                    ${liveCtx.liveMultiplex.backgroundColorLight})`
                  }}
                />
                <div className="multiplex-overview__total" >
                  <Counter
                    label={`Total levé à ${liveCtx.liveShow.place}`}
                    labelColor={liveCtx.liveMultiplex.fontColorHighlight}
                    count={liveCtx.liveShow.totalPledgesAmount / 100}
                    isBig
                    hasPin
                  />
                </div>
              </div>
            }
          />

        </main>
      </IonContent>
    </IonPage>
  )
}

export default MultiplexOverview