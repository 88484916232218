import { IonIcon, IonImg } from "@ionic/react";
import { close } from "ionicons/icons";
import Show from "../../../models/Show";
import BaseButton from "../../@Shared/BaseButton/BaseButton";
import BaseToggle from "../../@Shared/BaseToggle/BaseToggle";
import BaseTextInput from "../../@Shared/BaseTextInput/BaseTextInput";
import buildFormRules from "../../../helpers/buildFormRules";
import { useForm, Controller } from "react-hook-form";
import "./JoinForm.css";
import { useState } from "react";
import UserData from "../../../models/UserData";
import slugify from "slugify";

import API from "../../../services";

interface ContainerProps {
  onDismiss: Function;
  show: Show;
  liveCtx: any;
}

const JoinForm: React.FC<ContainerProps> = (props) => {
  const [shareInfosAgreed, setShareInfosAgreed] = useState(false);
  const [wantAReceipt, setWantAReceipt] = useState(false);
  const [isOnPlace, setIsOnPlace] = useState(false);

  const emailRegex =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmitHandler = async (data: any) => {

  let hasError = false;
  if (hasError) {
    return;
  } else {
    const customAmounts = await API.fetchCustomAmounts(
      props.show.id,
      data.email
    );
    const userData = new UserData(
      data.email,
      { ...data },
      isOnPlace,
      wantAReceipt,
      props.show.id,
      "",
      shareInfosAgreed,
      props.show.urlSlug,
      customAmounts || null,
      data.pseudo || null
    );
    props.liveCtx.liveUserDataHandler(userData);
  }
  };

  const getPresetRegistrations = () => {
    let presetsList = [];
    if (props.show.isFirstnamePresetOn) {
      presetsList.push({
        name: "firstname",
        label: "Prénom",
        required: props.show.isFirstnamePresetRequired,
      });
    }
    if (props.show.isLastnamePresetOn) {
      presetsList.push({
        name: "lastname",
        label: "Nom",
        required: props.show.isLastnamePresetRequired,
      });
    }
    if (props.show.isPhonePresetOn) {
      presetsList.push({
        name: "phone",
        label: "Téléphone",
        required: props.show.isPhonePresetRequired,
      });
    }
    if (props.show.isAddressFirstLinePresetOn) {
      presetsList.push({
        name: "address_first_line",
        label: "Adresse 1",
        required: props.show.isAddressFirstLinePresetRequired,
      });
    }
    if (props.show.isAddressSecondLinePresetOn) {
      presetsList.push({
        name: "address_second_line",
        label: "Adresse 2",
        required: props.show.isAddressSecondLinePresetRequired,
      });
    }
    if (props.show.isZipcodePresetOn) {
      presetsList.push({
        name: "zipcode",
        label: "Code Postal",
        required: props.show.isZipcodePresetRequired,
      });
    }
    if (props.show.isCityPresetOn) {
      presetsList.push({
        name: "city",
        label: "Ville",
        required: props.show.isCityPresetRequired,
      });
    }
    if (props.show.isCountryPresetOn) {
      presetsList.push({
        name: "country",
        label: "Pays",
        required: props.show.isCountryPresetRequired,
      });
    }
    if (props.show.isPseudoPresetOn) {
      presetsList.push({
        name: "pseudo",
        label: "Pseudo",
        required: props.show.isPseudoPresetRequired
      });
    }
    return presetsList;
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmitHandler)}
      className="join-form"
      data-testid="join-form"
      noValidate
    >
      <div className="join-form__header">
        <div className="join-form__header__spacer" />
        <div className="join-form__header__show-logo">
          {props.show.logoUrl && <IonImg src={props.show.logoUrl} />}
        </div>
        <div className="join-form__header__close">
          <IonIcon icon={close} onClick={() => props.onDismiss()} />
        </div>
      </div>
      <div className="join-form__title">Mes informations</div>
      <div className="join-form__form">
        {props.show.isOnPlace && props.show.isOnRemote && (
          <div className="join-form__form__on-place-ask">
            <div
              className={
                isOnPlace
                  ? "join-form__form__on-place-ask__button"
                  : "join-form__form__on-place-ask__button join-form__form__on-place-ask__button--active"
              }
            >
              <BaseButton
                type="button"
                expand
                tight
                onClick={() => setIsOnPlace(false)}
              >
                <span style={{ color: "white", fontSize: "12px" }}>
                  Je participe à distance
                </span>
              </BaseButton>
            </div>
            <div
              className={
                !isOnPlace
                  ? "join-form__form__on-place-ask__button"
                  : "join-form__form__on-place-ask__button join-form__form__on-place-ask__button--active"
              }
            >
              <BaseButton
                type="button"
                expand
                tight
                onClick={() => setIsOnPlace(true)}
              >
                <span style={{ color: "white", fontSize: "12px" }}>
                  Je participe sur place
                </span>
              </BaseButton>
            </div>
          </div>
        )}
        {props.show.hasTaxReceiptAvailable && (
          <div className="join-form__form__receipt-ask">
            <div
              className={
                !wantAReceipt
                  ? "join-form__form__receipt-ask__button"
                  : "join-form__form__receipt-ask__button join-form__form__receipt-ask__button--active"
              }
            >
              <BaseButton
                type="button"
                expand
                tight
                onClick={() => setWantAReceipt(true)}
              >
                <span style={{ color: "white", fontSize: "12px" }}>
                  Je souhaite un reçu fiscal
                </span>
              </BaseButton>
            </div>
            <div
              className={
                wantAReceipt
                  ? "join-form__form__receipt-ask__button"
                  : "join-form__form__receipt-ask__button join-form__form__receipt-ask__button--active"
              }
            >
              <BaseButton
                type="button"
                expand
                tight
                onClick={() => setWantAReceipt(false)}
              >
                <span style={{ color: "white", fontSize: "12px" }}>
                  Je ne souhaite pas de reçu
                </span>
              </BaseButton>
            </div>
          </div>
        )}
        <Controller
          key={"email_input"}
          name={"email"}
          control={control}
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: "Vous devez renseigner une adresse email.",
            },
            pattern: {
              value: emailRegex,
              message: "L'adresse email est invalide.",
            },
          }}
          render={({ field }) => {
            return (
              <BaseTextInput
                key={field.name}
                controller={field}
                error={errors["email"]?.message as string}
                label={"Adresse Email (obligatoire)"}
                type="email"
              />
            );
          }}
        />
        {getPresetRegistrations().map((registration) => (
          <Controller
            key={registration.name}
            name={registration.name}
            control={control}
            defaultValue=""
            rules={{
              required: registration.required,
            }}
            render={({ field }) => {
              return (
                <BaseTextInput
                  key={field.name}
                  controller={field}
                  error={errors[registration.name]?.message as string}
                  label={`${registration.label}${
                    registration.required ? " (Obligatoire)" : ""
                  }`}
                />
              );
            }}
          />
        ))}
        {props.show?.customRegistrationsList?.map((registrationField, key) => {
          const inputName = slugify(registrationField.name, {
            replacement: "_",
            lower: true,
            strict: true,
            locale: "en",
            trim: true,
          });
          return registrationField.type === "CHECKBOX" ? (
            <Controller
              key={inputName + key}
              control={control}
              name={inputName}
              rules={buildFormRules(registrationField)}
              defaultValue={false}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <div
                  className="join-form__form__checkbox-input"
                  style={{
                    borderColor: error ? "#ef4444" : undefined,
                  }}
                >
                  <label>
                    {registrationField.name +
                      (registrationField.required ? " (Obligatoire)" : "")}
                  </label>
                  <input
                    type="checkbox"
                    onBlur={onBlur}
                    onChange={onChange}
                    checked={value}
                    ref={ref}
                  />
                </div>
              )}
            />
          ) : (
            <Controller
              key={inputName + key}
              name={inputName}
              control={control}
              rules={buildFormRules(registrationField)}
              defaultValue=""
              render={({ field }) => {
                return (
                  <BaseTextInput
                    key={field.name}
                    controller={field}
                    error={errors[inputName]?.message as string}
                    type={
                      registrationField.type === "NUMBER" ? "number" : undefined
                    }
                    label={
                      registrationField.required
                        ? registrationField.name + " (obligatoire)"
                        : registrationField.name
                    }
                  />
                );
              }}
            />
          );
        })}
      </div>
      <div className="join-form__controls">
        <div className="join-form__controls__ask-share-infos">
          <BaseToggle
            isActive={shareInfosAgreed}
            onClick={() => setShareInfosAgreed(!shareInfosAgreed)}
          />
          <span className="join-form__controls__ask-share-infos__label">
            J'accepte de partager mes informations dans le cadre de cet
            événement
          </span>
        </div>
        <div className="join-form__controls__buttons">
          <BaseButton
            type="button"
            tight
            outline
            onClick={() => props.onDismiss()}
          >
            Annuler
          </BaseButton>
          <BaseButton tight type="submit" margins={"0 0 0 16px"}>
            <span style={{ color: "white" }}>Rejoindre</span>
          </BaseButton>
        </div>
      </div>
    </form>
  );
};

export default JoinForm;
