export const FIELD_REQUIRED = "Ce champs doit être renseigné";
export const EMAIL_FORMAT_REQUIRED =
  "Ce champs doit être renseigné avec une adresse email valide";
export const MIN_LENGTH = (length: number) =>
  `Ce champs doit contenir au minimum ${length.toString()} caractères`;
export const GENERIC_FETCH_ALERT = {
  header: "Erreur lors de la récupération des données",
  detail:
    "Si le problème persiste, merci de contacter les administrateurs de la plateforme",
};
