import { IonIcon, IonImg } from "@ionic/react";
import { close } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import LiveContext from "../../../contexts/live.ctx";
import getSymbolFromCurrency from "../../../helpers/getSymbolFromCurrency";
import BaseButton from "../../@Shared/BaseButton/BaseButton";
import "./LiveTopPanel.css";

interface ContainerProps {}

const LiveTopPanel: React.FC<ContainerProps> = (props) => {
  const liveCtx = useContext(LiveContext);
  const [isActive, setIsActive] = useState(false);
  const [displayedPledgesList, setDisplayedPledgesList] = useState(
    [] as { title: string; logoUrl: string | undefined; totalAmount: number }[]
  );
  const [totalPledgesAmount, setTotalPledgesAmount] = useState(0);

  useEffect(() => {
    const computedPledgeList = [...liveCtx.liveShowProjects]
      .sort((a, b) => (a.position > b.position ? 1 : -1))
      ?.map((project) => {
        const projectTotalAmount = liveCtx.liveUserPledges.reduce(
          (projectAmount, pledge) => {
            if (pledge.projectId == project.id) {
              const total = projectAmount + pledge.amount;
              return total;
            }
            return projectAmount;
          },
          0
        );
        const formatProject = {
          title: project.name,
          logoUrl: project.logoUrl,
          totalAmount: projectTotalAmount,
        };

        return formatProject;
      });
    const totalShowAmount = liveCtx.liveUserPledges.reduce((total, pledge) => {
      return total + pledge.amount;
    }, 0);
    setDisplayedPledgesList(computedPledgeList ? computedPledgeList : []);
    setTotalPledgesAmount(totalShowAmount);
  }, [liveCtx.liveShow, liveCtx.liveUserPledges, liveCtx.liveShowProjects]);

  return (
    <div
      className={
        isActive
          ? "live-top-panel live-top-panel--is-active"
          : "live-top-panel live-top-panel--is-unactive"
      }
      data-testid="live-top-panel"
    >
      <div className="live-top-panel__active-display">
        <div className="live-top-panel__active-display__header">
          <span>Mes Promesses</span>
          <span>
            <IonIcon icon={close} onClick={() => setIsActive(false)} />
          </span>
        </div>
        <div className="live-top-panel__active-display__pledges-list">
          {displayedPledgesList.map((projectPledges) => {
            return (
              <div
                className={"live-top-panel__active-display__pledges-list__item"}
                key={projectPledges.title}
              >
                <div
                  className={
                    "live-top-panel__active-display__pledges-list__item__infos"
                  }
                >
                  <div className="live-top-panel__active-display__pledges-list__item__infos__logo">
                    {projectPledges.logoUrl && (
                      <IonImg src={projectPledges.logoUrl} />
                    )}
                  </div>
                  <span>{projectPledges.title}</span>
                </div>
                <div className="live-top-panel__active-display__pledges-list__item__amount">
                  {Math.floor(projectPledges.totalAmount).toLocaleString(
                    undefined,
                    { maximumFractionDigits: 0 }
                  ) +
                    " " +
                    (getSymbolFromCurrency(liveCtx.liveShow.pledgesCurrency) ||
                      "€")}
                </div>
              </div>
            );
          })}
        </div>
        <div className="live-top-panel__active-display__footer">
          <div className="live-top-panel__active-display__footer__pledges-total">
            <span>Total de mes promesses :</span>
            <span>
              {totalPledgesAmount.toLocaleString(undefined, {
                maximumFractionDigits: 0,
              })}{" "}
              {getSymbolFromCurrency(liveCtx.liveShow.pledgesCurrency) || "€"}
            </span>
          </div>
          <div className="live-top-panel__active-display__footer__action">
            <BaseButton
              outline
              tight
              expand
              onClick={() => liveCtx.userQuitHandler()}
            >
              Quitter l'évènement
            </BaseButton>
          </div>
        </div>
      </div>
      <div className="live-top-panel__unactive-display">
        <div
          className="live-top-panel__unactive-display__hamburger"
          onClick={() => setIsActive(true)}
        >
          <div />
          <div />
          <div />
        </div>
        <div className="live-top-panel__unactive-display__user-amount">
          Mes Promesses :{" "}
          {totalPledgesAmount.toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })}{" "}
          {getSymbolFromCurrency(liveCtx.liveShow.pledgesCurrency) || "€"}
        </div>
      </div>
    </div>
  );
};

export default LiveTopPanel;
