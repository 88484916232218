import { useContext, useEffect, useRef, useState } from "react";
import LiveContext from "../../../contexts/live.ctx";
import BaseTextInput from "../../@Shared/BaseTextInput/BaseTextInput";
import useWindowSize from "../../../hooks/useWindowSize";
import "./LiveChat.css";
import { IonIcon } from "@ionic/react";
import { send } from "ionicons/icons";
import BaseButton from "../../@Shared/BaseButton/BaseButton";
import API from "../../../services";
import getSymbolFromCurrency from "../../../helpers/getSymbolFromCurrency";
import { ChatMessage } from "../../../types";
import { useParams, useLocation } from "react-router-dom";
interface ContainerProps {}

const LiveChat: React.FC<ContainerProps> = (props) => {
  const { slug } = useParams<{ slug: string }>();
  const { pathname } = useLocation();
  const liveCtx = useContext(LiveContext);
  console.log("🚀 ~ file: LiveChat.tsx ~ line 19 ~ liveCtx", liveCtx);
  const messageZoneRef = useRef<HTMLDivElement>(null);
  const { width, height } = useWindowSize();
  const [preventAutoScroll, setPreventAutoScroll] = useState(false);
  const [message, setMessage] = useState("");
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [chatMessages, setChatMessages] = useState([] as ChatMessage[]);

  useEffect(() => {
    if (pathname.includes("livechat")) {
      liveCtx.initLiveData(slug);
    }

    return () => liveCtx.dismissListeners();
  }, []);

  const scrollToChatBottom = () => {
    if (messageZoneRef.current != null) {
      messageZoneRef.current.scrollTo({
        top: messageZoneRef.current.scrollHeight
        //behavior: "smooth",
      });
    }
  };

  const sendMessage = () => {
    setIsSendingMessage(true);
    API.sendChatMessage(liveCtx.liveShow.id, liveCtx.liveUserData, message)
      .then(() => {
        setMessage("");
        setIsSendingMessage(false);
      })
      .catch(() => {
        setMessage("");
        setIsSendingMessage(false);
      });
  };

  useEffect(() => {
    setChatMessages(liveCtx.liveChatMessages);
  }, [liveCtx.liveChatMessages]);

  useEffect(() => {
    if (!preventAutoScroll) {
      scrollToChatBottom();
    }
  }, [width, height, chatMessages]);

  return (
    <div className="live-chat" data-testid="live-chat">
      <div
        className="live-chat__messages-zone"
        ref={messageZoneRef}
        onTouchStart={() => setPreventAutoScroll(true)}
        onTouchEnd={() => setPreventAutoScroll(false)}
      >
        {[...chatMessages]
          .sort((a, b) => (a.date > b.date ? 1 : -1))
          .map((msg) => {
            return (
              <div
                className={
                  msg.pledgeAmount
                    ? "live-chat__messages-zone__item live-chat__messages-zone__item--has-pledge"
                    : "live-chat__messages-zone__item"
                }
                key={msg.id}
              >
                <span
                  className={
                    msg.isAdmin
                      ? "live-chat__messages-zone__item__username--admin"
                      : ""
                  }
                >{`${msg.pseudo ? msg.pseudo : msg.username}${
                  msg.pledgeAmount
                    ? " donne " +
                      (msg.pledgeAmount / 100).toLocaleString(undefined, {
                        maximumFractionDigits: 0
                      }) +
                      " " +
                      (getSymbolFromCurrency(
                        liveCtx.liveShow.pledgesCurrency
                      ) || "€")
                    : ""
                }`}</span>
                <span>{msg.content || ""}</span>
              </div>
            );
          })}
      </div>
      {!pathname.includes("livechat") && (
        <div className="live-chat__writing-zone">
          <div
            className="live-chat__writing-zone__input-container"
            onKeyDown={(event) =>
              event.key === "Enter" && message ? sendMessage() : {}
            }
          >
            <BaseTextInput
              placeholder="Mon message..."
              controller={{
                value: message,
                onChange: (event: any) => setMessage(event.target.value)
              }}
            />
          </div>
          <div className="live-chat__writing-zone__send-btn">
            <BaseButton
              outline
              isLoading={isSendingMessage}
              disabled={!message || isSendingMessage}
              onClick={sendMessage}
            >
              <span>
                <IonIcon icon={send} />
              </span>
            </BaseButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default LiveChat;
