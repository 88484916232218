import HomeFooter from "../../components/Home/HomeFooter/HomeFooter";
import HomeHeader from "../../components/Home/HomeHeader/HomeHeader";
import HomeShowsGrid from "../../components/Home/HomeShowsGrid/HomeShowsGrid";
import HomeSegments from "../../components/Home/HomeSegments/HomeSegments";

import "./HomePage.css";
import { IonContent, IonPage } from "@ionic/react";

const Home: React.FC = () => {
  return (
    <IonPage className="home-page" data-testid="home-page">
      <HomeHeader></HomeHeader>
      <IonContent>
        <main className="home-page__main" data-testid="home-page__main">
          <h2 className="home-page__main__title">
            {"Les évènements".toUpperCase()}
          </h2>
          <HomeSegments />
          <HomeShowsGrid />
        </main>
      </IonContent>
      <HomeFooter></HomeFooter>
    </IonPage>
  );
};

export default Home;
