import {useEffect} from "react";

const useDocumentTitle = (title: string, prevailOnUnmount = false) => {
    useEffect(() => {
        document.title = title;
    }, [title]);

    useEffect(() => () => {
        if (!prevailOnUnmount) {
            document.title = "Dons.live";
        }
    }, [])
}

export default useDocumentTitle