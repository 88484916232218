import { IonContent, IonPage, useIonModal } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import LiveTopPanel from "../../components/Live/LiveTopPanel/LiveTopPanel";
import LiveBottomPanel from "../../components/Live/LiveBottomPanel/LiveBottomPanel";
import LiveContext from "../../contexts/live.ctx";
import LiveBanner from "../../components/Live/LiveBanner/LiveBanner";
import LiveStream from "../../components/Live/LiveStream/LiveStream";
import LiveAmounts from "../../components/Live/LiveAmounts/LiveAmounts";
import LiveCurrentTitle from "../../components/Live/LiveCurrentTitle/LiveCurrentTitle";
import LiveNoStreamProgression from "../../components/Live/LiveNoStreamProgression/LiveNoStreamProgression";
import ShowEndingModal from "../../components/Modals/ShowEndingModal/ShowEndingModal";
import routes from "../../routes";
import "./LivePage.css";

export enum BottomPanelMode {
  "CHAT",
  "PROJECT_SELECTION",
  "FREE_PLEDGE",
  "PLEDGE",
}

const LivePage: React.FC = () => {
  const liveCtx = useContext(LiveContext);
  const history = useHistory();
  const [bottomPanelMode, setBottomPanelMode] = useState(BottomPanelMode.CHAT);
  const [pendingPledge, setPendingPledge] = useState(0); // -1 if free amount
  const [pendingPledgeProjectId, setPendingPledgeProjectId] = useState("");
  const [endingModalIsOn, setEndingModalIsOn] = useState(false);

  useEffect(() => {
    if (liveCtx.liveUserData.eventId) {
      liveCtx.initLiveData(liveCtx.liveUserData.eventId);
    } else {
      history.replace(routes.HOME_PAGE);
    }

    return () => liveCtx.dismissListeners();
  }, []);

  const displayChat = () => {
    setBottomPanelMode(BottomPanelMode.CHAT);
    setPendingPledge(0);
    setPendingPledgeProjectId("");
  };

  const displayProjectSelection = (amount: number) => {
    setPendingPledge(amount);
    if (liveCtx.liveShow.hasNoProjects) {
      setPendingPledgeProjectId("");
      setBottomPanelMode(
        amount == -1 ? BottomPanelMode.FREE_PLEDGE : BottomPanelMode.PLEDGE
      );
    } else {
      setPendingPledgeProjectId(liveCtx.liveShow.currentProjectId || "");
      setBottomPanelMode(BottomPanelMode.PROJECT_SELECTION);
    }
  };

  const displayPledgeConfirmation = (selectedProjectId: string) => {
    setPendingPledgeProjectId(selectedProjectId);
    setBottomPanelMode(
      pendingPledge == -1 ? BottomPanelMode.FREE_PLEDGE : BottomPanelMode.PLEDGE
    );
  };

  useEffect(() => {
    if (liveCtx.liveShow.isClosed || liveCtx.userHasQuit) {
      setEndingModalIsOn(true);
    } else {
      setEndingModalIsOn(false);
    }
    return () => setEndingModalIsOn(false);
  }, [liveCtx.liveShow.isClosed, liveCtx.userHasQuit]);

  return (
    <>
      <ShowEndingModal isOpen={endingModalIsOn} />
      <IonPage className="live-page" data-testid="live-page">
        <IonContent scrollY={false}>
          <main className="live-page__main">
            <div className="live-main-window">
              <LiveBanner />
              {liveCtx.liveShow.isStreamingEnabled ? (
                <LiveStream />
              ) : (
                <LiveNoStreamProgression />
              )}
              <LiveCurrentTitle />
              <LiveAmounts onSelect={displayProjectSelection} />
            </div>
            <LiveBottomPanel
              mode={bottomPanelMode}
              pendingPledge={pendingPledge}
              pendingPledgeProjectId={pendingPledgeProjectId}
              backToChat={displayChat}
              goToPledgeConfirmation={displayPledgeConfirmation}
            />
            <LiveTopPanel />
          </main>
        </IonContent>
      </IonPage>
    </>
  );
};

export default LivePage;
