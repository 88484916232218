import { IonIcon } from "@ionic/react";
import dayjs from "dayjs";
import { close } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import Show from "../../../models/Show";
import BaseButton from "../../@Shared/BaseButton/BaseButton";
import JoinForm from "../../Join/JoinForm/JoinForm";
import ShowCardCalendar from "../../Shows/ShowCardCalendar/ShowCardCalendar";
import ShowCardInfos from "../../Shows/ShowCardInfos/ShowCardInfos";
import "./ShowDetailModal.css";

interface ContainerProps {
  show: Show;
  liveCtx: any;
  onDismiss: Function;
}

const ShowDetailModal: React.FC<ContainerProps> = (props) => {
  const [joinFormMode, setJoinFormMode] = useState(false);

  const detailActionHandler = () => {
    if (props.show.isClosed) {
      if (props.show.pastButtonUrl) {
        window.open(props.show.pastButtonUrl, "_blank");
      }
      return;
    }
    if (
      dayjs(props.show.startDate).isAfter(dayjs()) &&
      !dayjs(props.show.startDate).isSame(dayjs(), "day")
    ) {
      if (props.show.comingButtonUrl) {
        window.open(props.show.comingButtonUrl, "_blank");
      }
      return;
    }
    setJoinFormMode(true);
  };

  const hasButton = () => {
    if (props.show.isClosed) {
      if (props.show.pastButtonText) {
        return true;
      }
      return false;
    }
    if (
      dayjs(props.show.startDate).isAfter(dayjs()) &&
      !dayjs(props.show.startDate).isSame(dayjs(), "day")
    ) {
      if (props.show.comingButtonText) {
        return true;
      }
      return false;
    }
    return true;
  };

  return props.show.id ? (
    !joinFormMode ? (
      <div
        className="show-detail-modal"
        data-testid="show-detail-modal"
        style={{ backgroundImage: `url(${props.show.coverUrl})` }}
      >
        <div className="show-detail-modal__header">
          <div className="show-detail-modal__header__calendar">
            <ShowCardCalendar date={props.show.startDate} />
          </div>
          <div className="show-detail-modal__header__close">
            <IonIcon icon={close} onClick={() => props.onDismiss()} />
          </div>
        </div>
        <div className="show-detail-modal__content">
          <div className="show-detail-modal__content__inner">
            <div className="show-detail-modal__content__inner__infos">
              <ShowCardInfos show={props.show} />
            </div>
            <div className="show-detail-modal__content__inner__action">
              {hasButton() && (
                <BaseButton tight expand onClick={detailActionHandler}>
                  <span style={{ color: "white" }}>
                    {props.show.isClosed
                      ? props.show.pastButtonText.toUpperCase()
                      : dayjs(props.show.startDate).isAfter(dayjs()) &&
                        !dayjs(props.show.startDate).isSame(dayjs(), "day")
                      ? props.show.comingButtonText.toUpperCase()
                      : "Rejoindre l'évènement".toUpperCase()}
                  </span>
                </BaseButton>
              )}
            </div>
            <div className="show-detail-modal__content__inner__description">
              {props.show.description}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="show-detail-modal--join-form-mode">
        <JoinForm
          show={props.show}
          onDismiss={props.onDismiss}
          liveCtx={props.liveCtx}
        />
      </div>
    )
  ) : (
    <div />
  );
};

export default ShowDetailModal;
