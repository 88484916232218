import { useContext, useEffect, useState } from "react";
import "./DirectJoinPage.css";
import { IonContent, IonPage, useIonModal } from "@ionic/react";
import API from "../../services";
import { RouteComponentProps, useHistory } from "react-router";
import BaseLogo from "../../components/@Shared/BaseLogo/BaseLogo";
import Show from "../../models/Show";
import routes from "../../routes";
import JoinForm from "../../components/Join/JoinForm/JoinForm";
import LiveContext from "../../contexts/live.ctx";

interface DirectJoinPageProps
  extends RouteComponentProps<{
    slug: string;
  }> {}

const DirectJoinPage: React.FC<DirectJoinPageProps> = ({ match }) => {
  const [directJoinShow, setDirectJoinShow] = useState({} as Show);
  const liveCtx = useContext(LiveContext);
  const history = useHistory();

  const [present, dismiss] = useIonModal(JoinForm, {
    show: directJoinShow,
    liveCtx,
    onDismiss: () => {
      history.push(routes.HOME_PAGE);
      dismiss();
    },
  });

  useEffect(() => {
    if (liveCtx.liveUserData.eventId) {
      dismiss();
      setTimeout(() => {
        history.push(`/${match.params.slug}/live`);
      }, 350);
    }
  }, [liveCtx.liveUserData]);

  useEffect(() => {
    async function fetchShowBySlug() {
      API.getShowBySlug(match.params.slug)
        .then((show: Show) => {
          if (!show) {
            return history.push(routes.HOME_PAGE);
          }
          setDirectJoinShow(show);
          present();
          if (!show.isClosed) {
            liveCtx.checkPreviousUserData();
          }
        })
        .catch((err) => {
          return history.push(routes.HOME_PAGE);
        });
    }
    if (!directJoinShow.id) {
      fetchShowBySlug();
    }
  }, []);

  return (
    <IonPage className="direct-join-page" data-testid="direct-join-page">
      <IonContent>
        <main className="direct-join-page__main">
          <a href={routes.HOME_PAGE}>
            <BaseLogo />
          </a>
        </main>
      </IonContent>
    </IonPage>
  );
};

export default DirectJoinPage;
