import { useContext, useEffect, useState } from "react";
import "./CounterPage.css";
import { IonContent, IonImg, IonPage } from "@ionic/react";
import API from "../../services";
import { RouteComponentProps } from "react-router";
import BaseLogo from "../../components/@Shared/BaseLogo/BaseLogo";
import Show from "../../models/Show";
import LiveContext from "../../contexts/live.ctx";
import getSymbolFromCurrency from "../../helpers/getSymbolFromCurrency";
import DashedProgressBar from "../../components/Multiplex/DashedProgressBar/DashedProgressBar";
import useDocumentTitle from "../../helpers/changeTabName";

interface CounterPageProps
  extends RouteComponentProps<{
    slug: string;
  }> {}

const CounterPage: React.FC<CounterPageProps> = (props) => {
  const liveCtx = useContext(LiveContext);
  const [currentProjectPosition, setCurrentProjectPosition] = useState(0);
  const [lockMode, setLockMode] = useState(false);
  const [currentPercentage, setCurrentPercentage] = useState(0);

  // #848AAE purple
  // #2CA795 success
  // #222428 background
  let success = "#2CA795";

  useEffect(() => {
    async function fetchShowBySlug() {
      API.getShowBySlug(props.match.params.slug)
        .then((show: Show) => {
          liveCtx.initLiveData(show.id);
        })
        .catch((err) => {
          return console.log(err);
        });
    }
    if (!liveCtx.liveShow.id) {
      fetchShowBySlug();
    }
  }, [liveCtx.liveShow.id]);

  useEffect(() => {
    if (props.location.search.split("?pos=")[1]) {
      setCurrentProjectPosition(+props.location.search.split("?pos=")[1]);
      setLockMode(true);
    }
  }, []);

  const projectToUse = lockMode
    ? liveCtx.liveShowProjects.find(
        (proj) => proj.position === currentProjectPosition
      )
    : liveCtx.liveProject;

  useEffect(() => {
    if (projectToUse) {
      const calcPercentage = Math.floor(
        ((projectToUse.totalPledgesAmount || 0) / (projectToUse.goal || 0)) *
          100
      );
      if (calcPercentage != currentPercentage) {
        setCurrentPercentage(calcPercentage);
      }
    }
  }, [projectToUse]);

  useDocumentTitle(projectToUse?.name || "Dons.live")

  return (
    <IonPage className="counter-page" data-testid="counter-page">
      <IonContent>
        <main className="counter-page__main">
          {liveCtx.liveShow.id && projectToUse && (
            <div
              className="counter-page__main__counter"
              style={{
                backgroundColor: liveCtx.liveShow.backgroundColor
              }}
            >
              {!liveCtx.liveShow.hasNoProjects ? (
                <div className="counter-page__main__counter__show-total-pledges">
                  <span style={{ color: liveCtx.liveShow.fontColor }}>
                    {liveCtx.liveShow.name}
                  </span>
                  <span style={{ color: liveCtx.liveShow.fontColor }}>
                    {(liveCtx.liveShow.totalPledgesAmount / 100).toLocaleString(
                      undefined,
                      { maximumFractionDigits: 0 }
                    ) +
                      " " +
                      getSymbolFromCurrency(liveCtx.liveShow.pledgesCurrency)}
                  </span>
                </div>
              ) : (
                <div />
              )}
              <div className="counter-page__main__counter__project-counter">
                {projectToUse.logoUrl && (
                  <div
                    className="counter-page__main__counter__project-counter__logo"
                    style={{
                      backgroundColor: projectToUse.backgroundColorLogo
                    }}
                  >
                    {projectToUse.logoUrl && (
                      <IonImg src={projectToUse.logoUrl} />
                    )}
                  </div>
                )}
                <span
                  style={{ color: liveCtx.liveShow.fontColor }}
                  className="counter-page__main__counter__project-counter__current-project-name"
                >
                  {projectToUse?.name || "-"}
                </span>
                <div
                  style={{
                    color: liveCtx.liveShow.fontColor
                  }}
                  className="counter-page__main__counter__project-counter__amount"
                >
                  {projectToUse.totalPledgesAmount !== undefined
                    ? (projectToUse.totalPledgesAmount / 100).toLocaleString(
                        undefined,
                        { maximumFractionDigits: 0 }
                      ) +
                      " " +
                      getSymbolFromCurrency(liveCtx.liveShow.pledgesCurrency)
                    : "-"}
                </div>
              </div>

              <div className="counter-page__main__counter__project-counter__progress-bar">
                {!liveCtx.liveShow.hasNoProjects ? (
                  <DashedProgressBar
                    colorBase={liveCtx.liveShow.fontColor}
                    colorHighlight={liveCtx.liveShow.backgroundColorPledgeBeyond}
                    count={projectToUse.totalPledgesAmount}
                    objective={projectToUse.goal}
                  />
                ) : (
                  <DashedProgressBar
                    colorBase={liveCtx.liveShow.fontColor}
                    colorHighlight={liveCtx.liveShow.backgroundColorPledgeBeyond}
                    count={liveCtx.liveShow.totalPledgesAmount}
                    objective={liveCtx.liveShow.globalShowGoal}
                  />
                )}
                <div className="counter-page__main__counter__project-counter__goal"
                     style={{color: liveCtx.liveShow.backgroundColorPledgeBeyond}}
                >
                  {!liveCtx.liveShow.hasNoProjects ? (
                    <>{projectToUse.goal /100 } {getSymbolFromCurrency(liveCtx.liveShow.pledgesCurrency)}</>
                  ) : (
                    <>{liveCtx.liveShow.globalShowGoal / 100} {getSymbolFromCurrency(liveCtx.liveShow.pledgesCurrency)}</>
                  )}
                </div>
              </div>
            </div>
          )}
        </main>
      </IonContent>
    </IonPage>
  );
};

export default CounterPage;
