import { IonContent, IonPage } from "@ionic/react";
import "./Multiplex.css";
import {useContext, useEffect} from "react";
import API from "../../services";
import Multiplex from "../../models/Multiplex";
import LiveContext from "../../contexts/live.ctx";
import {RouteComponentProps} from "react-router";
import Show from "../../models/Show";
import { ReactComponent as Pin } from "../../assets/icon/Pin.svg"
import { ReactComponent as Heart } from "../../assets/icon/HeartShape.svg"
import DashedProgressBar from "../../components/Multiplex/DashedProgressBar/DashedProgressBar";
import Counter from "../../components/Multiplex/Counter/Counter";
import ContainerWithGradient from "../../components/Multiplex/ContainerWithGradient/ContainerWithGradient";
import getSymbolFromCurrency from "../../helpers/getSymbolFromCurrency";
import formatedNumbers from "../../helpers/formatedNumbersWithDot";

interface CounterPageProps
  extends RouteComponentProps<{
    slug: string;
  }> {}

const MultiplexPage: React.FC<CounterPageProps> = (props) => {
  const liveCtx = useContext(LiveContext);

  useEffect(() => {
    async function fetchShowBySlug() {
      API.getShowBySlug(props.match.params.slug)
        .then((show: Show) => {
          console.log(show)
          liveCtx.initLiveData(show.id);
        })
        .catch((err) => {
          return console.log(err);
        });
    }
    if (!liveCtx.liveShow.id) {
      fetchShowBySlug();
    }
  }, [liveCtx.liveShow.id]);

  useEffect(() => {
    if (liveCtx.liveShow.multiplexId) {
      API.getMultiplexBySlug(liveCtx.liveShow.multiplexId)
        .then((multiplex: Multiplex) => {
          liveCtx.initMultiplex(multiplex);
        })
        .catch((err) => {
          return console.log(err);
        });
    }
  }, [
    liveCtx.liveShow.multiplexId
  ]);

  useEffect(() => {
    if (liveCtx.liveShow.multiplexId && liveCtx.liveMultiplex.currentDashboardId) {
      const currentDashboard = liveCtx.liveMultiplexDashboards.find(
        dashboard => dashboard.id === liveCtx.liveMultiplex.currentDashboardId)
      if (currentDashboard) {
        liveCtx.liveDashboardHandler(currentDashboard)
        API.listenToSelectedDashboardLocalities(
          liveCtx.liveShow.multiplexId,
          currentDashboard.id,
          liveCtx.liveFocusedLocalitiesHandler
        )
      }
    }
  }, [
    liveCtx.liveMultiplex.currentDashboardId,
    liveCtx.liveMultiplexDashboards
  ]);

  return (
    <IonPage className="multiplex-page" data-testid="multiplex-page">
      <IonContent>
        <main
          className="multiplex__main"
          style={{
            color: liveCtx.liveMultiplex.fontColor,
            background: `radial-gradient(at 50% 130%, 
            ${liveCtx.liveMultiplex.backgroundColorLight} 9%, ${liveCtx.liveMultiplex.backgroundColorDark} 70%)`
          }}
        >
          <div className="multiplex__main__header">
            <div className="multiplex__main__col">
              <div className="multiplex__main__multiplex-card">
                <div className="multiplex__main__multiplex-card__infos">
                  {liveCtx.liveMultiplex.logoUrl && liveCtx.liveMultiplex.logoUrl !== "" && (
                    <img
                      src={liveCtx.liveMultiplex.logoUrl}
                      alt="logo"
                    />
                  )}
                </div>
                <h1 className="multiplex__main__multiplex-card__title">
                  {liveCtx.liveMultiplex.name}
                  {liveCtx.liveMultiplex.subtitle !== "" && liveCtx.liveMultiplex.subtitle !== "-" && (
                    <span
                      className="multiplex__main__multiplex-card__title-span"
                      style={{
                        color: liveCtx.liveMultiplex.backgroundColorLight
                      }}
                    >
                      &bull; {liveCtx.liveMultiplex.subtitle}
                    </span>
                  )}
                </h1>
              </div>
            </div>

            <div className="multiplex__main__col">
              <div className="multiplex__main__dashboard-header">
                {(liveCtx.liveDashboard.logoUrl && liveCtx.liveDashboard.logoUrl !== "") ?
                  <img
                    src={liveCtx.liveDashboard.logoUrl}
                    alt="logo"
                  />
                  :
                  <p>{liveCtx.liveDashboard.name}</p>
                }
              </div>
            </div>
          </div>

          <div className="multiplex__main__body">
            <div className="multiplex__main__col">
              <div
                className="multiplex__main__video-container"
                style={{
                  border: `3px solid ${liveCtx.liveMultiplex.backgroundColorLight}`
                }}
              >
                <div className="multiplex__main__video"></div>
              </div>
              <div className="multiplex__main__multiplex-tracker">
                <div className="multiplex__main__multiplex-tracker__content">
                  <Counter
                    label="Levée totale de la soirée"
                    labelColor={liveCtx.liveMultiplex.fontColorHighlight}
                    count={ liveCtx.liveMultiplex.totalPledgesAmount / 100}
                    hasSpecialFormat
                    isBig
                  />
                </div>
                <Heart
                  className="multiplex__multiplex-tracker__heart"
                  style={{ color: liveCtx.liveMultiplex.backgroundColorDark }}
                />
              </div>
            </div>

            <div className="multiplex__main__col">
              <div className="multiplex__main__conditional-box-container">
                {liveCtx.liveFocusedLocalities.length === 0 ? (
                  <ContainerWithGradient className="multiplex__main__conditional-box">
                    <>
                      <div
                        className="multiplex__main__location-pile"
                        style={{
                          backgroundColor: liveCtx.liveMultiplex.backgroundColorLight
                        }}
                      >
                        <Pin/>
                        {liveCtx.liveShow.place}
                      </div>
                      <Counter label="Levée pour le projet"
                               labelColor={liveCtx.liveMultiplex.fontColorHighlight}
                               count={liveCtx.liveProject.totalPledgesAmount / 100}
                               isBig
                      />
                    </>
                  </ContainerWithGradient>
                ) : (
                  <ContainerWithGradient className="multiplex__main__conditional-box">
                    <div className="multiplex__main__projects-list">
                      {liveCtx.liveFocusedLocalities.map(locality =>
                        <div
                          className="multiplex__main__highlight-location"
                          style={{
                            color: liveCtx.liveMultiplex.fontColorHighlight
                          }}
                          key={locality.id}
                        >
                          <Pin />
                          <div>{locality.place} &bull;</div>
                          <div
                            className="multiplex__main__highlight-location__counter"
                            style={{
                              color: liveCtx.liveMultiplex.fontColor
                            }}
                          >
                            {locality.totalPledgesAmount ? (
                              <span>{formatedNumbers(locality.totalPledgesAmount / 100)}</span>
                            ) : (
                              <span>0</span>
                            )}
                            {getSymbolFromCurrency(liveCtx.liveShow.pledgesCurrency)}
                          </div>
                        </div>
                      )}
                    </div>
                  </ContainerWithGradient>
                )}
              </div>

              <ContainerWithGradient className="multiplex__main__single-box">
                <>
                  <Counter label="Total levé sur le projet"
                           labelColor={liveCtx.liveMultiplex.fontColorHighlight}
                           count={liveCtx.liveDashboard.totalPledgesAmount / 100}
                           isBig
                  />
                  <DashedProgressBar
                    colorBase={liveCtx.liveMultiplex.fontColor}
                    colorHighlight={liveCtx.liveMultiplex.fontColorHighlight}
                    count={liveCtx.liveDashboard.totalPledgesAmount}
                    objective={liveCtx.liveDashboard.goal}
                  />
                  <p className="multiplex__main__project-progress__meter-goal">
                    <span>Sur un objectif de </span>
                    {formatedNumbers(liveCtx.liveDashboard.goal / 100 | 0)}
                    {getSymbolFromCurrency(liveCtx.liveShow.pledgesCurrency)}
                  </p>
                </>

              </ContainerWithGradient>
            </div>
          </div>
        </main>
      </IonContent>
    </IonPage>
  )
}

export default MultiplexPage