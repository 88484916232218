import { useContext } from "react";
import LiveContext from "../../../contexts/live.ctx";
import "./LiveCurrentTitle.css";

interface ContainerProps {}

const LiveCurrentTitle: React.FC<ContainerProps> = (props) => {
  const liveCtx = useContext(LiveContext);
  return (
    <div className="live-current-title" data-testid="live-current-title">
      <span>
        {liveCtx.liveProject.name
          ? liveCtx.liveProject.name
          : liveCtx.liveShow.name}
      </span>
    </div>
  );
};

export default LiveCurrentTitle;
