import { IonFooter, IonTitle, IonToolbar } from "@ionic/react";
import "./HomeFooter.css";

interface ContainerProps {}

const HomeFooter: React.FC<ContainerProps> = (props) => {
  return (
    <IonFooter>
      <IonToolbar>
        <span className="home-footer__content">
          © Obole - 2021 -{" "}
          <a href="https://obole.eu/evenements">Plus d'informations</a>
        </span>
      </IonToolbar>
    </IonFooter>
  );
};

export default HomeFooter;
