import Show from "../../../models/Show";
import ShowCardCalendar from "../ShowCardCalendar/ShowCardCalendar";
import ShowCardInfos from "../ShowCardInfos/ShowCardInfos";
import "./ShowCard.css";

interface ContainerProps {
  show: Show;
  onClick?: Function;
}

const ShowCard: React.FC<ContainerProps> = (props) => {
  return (
    <div
      className="show-card"
      data-testid="show-card"
      style={{ backgroundImage: `url(${props.show.coverUrl})` }}
      onClick={() => (props.onClick ? props.onClick() : {})}
    >
      <div className="show-card__content">
        <div className="show-card__content__cal-container">
          <ShowCardCalendar date={props.show.startDate} />
        </div>
        <div className="show-card_content_infos-container">
          <ShowCardInfos show={props.show} />
        </div>
      </div>
    </div>
  );
};

export default ShowCard;
