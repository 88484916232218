export default class {
  public id;
  public amount;
  public projectId;
  public additionalInformations;
  public email;
  public date;
  public isAdmin;

  constructor(
    id: string,
    amount: number,
    projectId: string,
    additionalInformations: Record<any, any>,
    email: string,
    date: Date,
    isAdmin: false
  ) {
    this.id = id;
    this.amount = amount;
    this.projectId = projectId;
    this.additionalInformations = additionalInformations;
    this.email = email;
    this.date = date;
    this.isAdmin = isAdmin;
  }
  hydrate(payload: {
    id: string;
    amount: number;
    projectId: string;
    additionalInformations: Record<any, any>;
    email: string;
    date: Date;
    isAdmin: false;
  }) {
    this.id = payload.id;
    this.amount = payload.amount;
    this.projectId = payload.projectId;
    this.additionalInformations = payload.additionalInformations;
    this.email = payload.email;
    this.date = payload.date;
    this.isAdmin = payload.isAdmin;
  }
  get payload() {
    return {
      id: this.id,
      amount: this.amount * 100,
      projectId: this.projectId,
      additionalInformations: this.additionalInformations,
      email: this.email,
      date: this.date,
      isAdmin: this.isAdmin,
    };
  }
}
