import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import LiveContext from "../../../contexts/live.ctx";

import "./LiveBannerCountdown.css";

interface ContainerProps {
  value: string;
}

const LiveBannerCountdown: React.FC<ContainerProps> = (props) => {
  return (
    <div className="live-banner-countdown" data-testid="live-banner-countdown">
      <span>L'évènement va bientôt commencer</span>
      <span>{props.value ? props.value : "-"}</span>
    </div>
  );
};

export default LiveBannerCountdown;
