import "./Counter.css"
import { ReactComponent as Pin } from "../../../assets/icon/Pin.svg"
import formatedNumbers from "../../../helpers/formatedNumbersWithDot";

interface CounterProps {
  label: string
  labelColor: string
  count: number
  hasPin?: boolean
  hasSpecialFormat?: boolean
  isBig?: boolean
}

const Counter = (props: CounterProps) => {
  return(
    <div
      className={props.hasPin ? "counter--with-pin" : "counter"}
    >
      <div className="counter__header">
        {props.hasPin && (
          <Pin
            className="counter__header__pin"
            style={{
              color: props.hasPin ? props.labelColor : "inherit"
            }}
          />
        )}
        <p
          className={props.hasSpecialFormat ? "counter__header__label--alt" : "counter__header__label"}
          style={{
            color: props.hasSpecialFormat ? "inherit" : props.labelColor
          }}
        >{props.label}</p>
      </div>
      <p
        className={props.isBig ? "counter__value--large" : "counter__value"}
      >{formatedNumbers(props.count)}&euro;</p>
    </div>
  )
}

export default Counter