import {useContext} from "react";
import LiveContext from "../../../contexts/live.ctx";
import "./ContainerWithGradient.css"

type ContainerProps = {
  children: JSX.Element
  className?: string
}

const ContainerWithGradient = (props: ContainerProps) => {
  const liveCtx = useContext(LiveContext);

  return (
    <div className={`multiplex__container`+ (props.className ? ` ${props.className}` : "") }>
      <div
        className="multiplex__container__background"
        style={{
          background: `linear-gradient(45deg, 
                      ${liveCtx.liveMultiplex.backgroundColorLight},
                      ${liveCtx.liveMultiplex.fontColorHighlight})`
        }}
      />
      <div className="multiplex__container__content">
        {props.children}
      </div>
    </div>
  )
}

export default ContainerWithGradient