import Show from "../../../models/Show";
import ShowCardInfosIndicator from "../ShowCardInfosIndicator/ShowCardInfosIndicator";
import "./ShowCardInfos.css";

interface ContainerProps {
  show: Show;
}

const ShowCardInfos: React.FC<ContainerProps> = (props) => {
  return (
    <div className="show-card-infos" data-testid="show-card-infos">
      <div className="show-card-infos__place-container">{props.show.place}</div>
      <div className="show-card-infos__name-container">{props.show.name}</div>
      <div className="show-card-infos__subtitle-and-indicator">
        <div className="show-card-infos__subtitle">{props.show.subtitle}</div>
        <div className="show-card-infos__indicator">
          <ShowCardInfosIndicator show={props.show} />
        </div>
      </div>
    </div>
  );
};

export default ShowCardInfos;
