import { IonImg } from "@ionic/react";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import LiveContext from "../../../contexts/live.ctx";
import LiveBannerCountdown from "../LiveBannerCountdown/LiveBannerCountdown";
import LiveBannerProject from "../LiveBannerProject/LiveBannerProject";
import "./LiveBanner.css";

interface ContainerProps {}

const LiveBanner: React.FC<ContainerProps> = (props) => {
  const liveCtx = useContext(LiveContext);
  const [countdownDate, setCountdownDate] = useState("");
  const [hasBegun, setHasBegun] = useState(false);

  const updateCountdown = () => {
    if (countdownDate == "00:00:00") {
      setHasBegun(true);
      return;
    }
    setCountdownDate(
      dayjs(dayjs(liveCtx.liveShow.startDate).diff(dayjs()))
        .subtract(1, "hour")
        .format("HH[:]mm[:]ss")
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (dayjs(liveCtx.liveShow.startDate).isAfter(dayjs())) {
        updateCountdown();
      } else if (dayjs(liveCtx.liveShow.startDate).isBefore(dayjs())) {
        setHasBegun(true);
      }
      return () => clearTimeout(timer);
    }, 1000);
    return () => clearTimeout(timer);
  }, [countdownDate, liveCtx.liveShow.startDate]);

  return (
    <div className="live-banner" data-testid="live-banner">
      {!hasBegun ? (
        <LiveBannerCountdown value={countdownDate} />
      ) : !liveCtx.liveProject.id && !liveCtx.liveShow.hasNoProjects ? (
        <div className="live-banner__event-logo">
          <IonImg src={liveCtx.liveShow.logoUrl} />
        </div>
      ) : (
        <LiveBannerProject />
      )}
    </div>
  );
};

export default LiveBanner;
