import { IonImg, IonModal } from "@ionic/react";
import { useContext } from "react";
import LiveContext from "../../../contexts/live.ctx";
import BaseButton from "../../@Shared/BaseButton/BaseButton";
import "./ShowEndingModal.css";

interface ContainerProps {
  isOpen: boolean;
}

const ShowEndingModal: React.FC<ContainerProps> = (props) => {
  const liveCtx = useContext(LiveContext);
  return (
    <IonModal isOpen={props.isOpen}>
      <div className="show-ending-modal">
        <span className="show-ending-modal__title">
          Merci pour votre générosité !
        </span>
        <div className="show-ending-modal__show-infos">
          <div className="show-ending-modal__show-infos__logo">
            {liveCtx.liveShow.logoUrl && (
              <IonImg src={liveCtx.liveShow.logoUrl} />
            )}
          </div>
          <span className="show-ending-modal__show-infos__name">
            {liveCtx.liveShow.name}
          </span>
        </div>
        <p className="show-ending-modal__text">
          {liveCtx.liveShow.endMessage
            ? liveCtx.liveShow.endMessage
            : "Vous recevrez dans les prochains jours un mail des organisateurs vous indiquant les modalités de transformation de votre don."}
        </p>
        {liveCtx.liveShow.endButtonText && liveCtx.liveShow.endButtonUrl && (
          <a
            href={liveCtx.liveShow.endButtonUrl}
            className="show-ending-modal__after-link"
          >
            <BaseButton expand>
              <span style={{ color: "white" }}>
                {liveCtx.liveShow.endButtonText}
              </span>
            </BaseButton>
          </a>
        )}
      </div>
    </IonModal>
  );
};

export default ShowEndingModal;
