import "./BaseToggle.css";

interface ContainerProps {
  isActive: boolean;
  onClick: Function;
}

const BaseToggle: React.FC<ContainerProps> = (props) => {
  return (
    <div
      className={
        props.isActive ? "base-toggle base-toggle--active" : "base-toggle"
      }
      onClick={() => props.onClick()}
    >
      <div className="base-toggle__circle"></div>
    </div>
  );
};

export default BaseToggle;
