import { IonContent, IonPage } from "@ionic/react";
import LiveChat from "../../components/Live/LiveChat/LiveChat";

const Livechats: React.FC = () => {
  return (
    <IonPage className="home-page" data-testid="home-page">
      <IonContent>
        <LiveChat />
      </IonContent>
    </IonPage>
  );
};

export default Livechats;
