import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import HomePage from "./pages/Home/HomePage";
import DirectJoinPage from "./pages/DirectJoin/DirectJoinPage";
import LivePage from "./pages/Live/LivePage";
import CounterPage from "./pages/Counter/CounterPage";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import routes from "./routes";
import { HomeContextProvider } from "./contexts/home.ctx";
import { LiveContextProvider } from "./contexts/live.ctx";
import Livechats from "./pages/Live/LiveChats";

/* Multiplex */
import Multiplex from "./pages/Multiplex/Multiplex";
import MultiplexSummary from "./pages/Multiplex/MultiplexSummary";
import MultiplexJauge from "./pages/Multiplex/MultiplexJauge";
import MultiplexOverview from "./pages/Multiplex/MultiplexOverview";
import MultiplexEndScreen from "./pages/Multiplex/MultiplexEndScreen";

const App: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <LiveContextProvider>
            <Route exact path="/:slug/live">
              <LivePage />
            </Route>
            <Route
              path="/ukraine"
              component={() => {
                window.location.href =
                  "https://www.helloasso.com/associations/asah/collectes/agir-avec-les-edc-pour-l-ukraine";
                return null;
              }}
            />
            <Route exact path="/:slug/multiplex" component={Multiplex} />
            <Route exact path="/:slug/multiplexsummary" component={MultiplexSummary} />
            <Route exact path="/:slug/multiplexjauge" component={MultiplexJauge} />
            <Route exact path="/:slug/multiplexoverview" component={MultiplexOverview} />
            <Route exact path="/:slug/multiplexendscreen" component={MultiplexEndScreen} />
            <Route exact path="/:slug/counter" component={CounterPage} />
            <Route exact path="/:slug/livechat" component={Livechats} />
            <Route exact path="/:slug" component={DirectJoinPage} />
            <Route exact path={routes.HOME_PAGE}>
              <HomeContextProvider>
                <HomePage />
              </HomeContextProvider>
            </Route>
          </LiveContextProvider>
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
