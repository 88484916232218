import React, { useEffect, useState } from "react";
import { ChatMessage, Project } from "../types";
import Show from "../models/Show";
import UserData from "../models/UserData";
import API from "../services";
import { Storage } from "@ionic/storage";
import { useIonAlert } from "@ionic/react";
import Pledge from "../models/Pledge";
import Multiplex from "../models/Multiplex";
import {DashboardLocalCount, MultiplexDashboard} from "../models/Dashboard";

const LiveContext = React.createContext({
  rtListenersList: [] as Function[],
  liveUserData: new UserData("", {}, false, false, "", "", false, "", null),
  liveUserDataHandler: (userData: UserData) => {},
  liveUserPledges: [] as Pledge[],
  liveUserPledgeHandler: async (
    pledge: Pledge,
    messagePayload?: {
      showId: string;
      userData: UserData;
      messageContent: string;
    }
  ) => new Promise<boolean>(Promise.reject),
  liveShow: {} as Show,
  liveProject: {} as Project,
  liveChatMessages: [] as ChatMessage[],
  liveShowProjects: [] as Project[],
  initLiveData: (showId: string) => {},
  checkPreviousUserData: () => {},
  dismissListeners: () => {},
  userHasQuit: false,
  userQuitHandler: () => {},
  liveMultiplex: {} as Multiplex,
  liveMultiplexDashboards: [] as MultiplexDashboard[],
  liveDashboard: {} as MultiplexDashboard,
  liveFocusedLocalities: [] as DashboardLocalCount[],
  initMultiplex: (multiplex: Multiplex) => {},
  liveDashboardHandler: (dashboard: MultiplexDashboard) => {},
  liveFocusedLocalitiesHandler: (locality: DashboardLocalCount[]) => {}
});

export const LiveContextProvider: React.FC = (props) => {
  const [rtListenersList, setRtListenersList] = useState([] as Function[]);
  const [liveProjectUnsubscriber, setLiveProjectUnsubscriber] = useState(
    [] as Function[]
  );
  const [liveUserData, setLiveUserData] = useState(
    new UserData("", {}, false, false, "", "", false, "", null)
  );
  const [liveUserPledges, setLiveUserPledges] = useState([] as Pledge[]);
  const [liveShow, setLiveShow] = useState({} as Show);
  const [liveShowProjects, setLiveShowProjects] = useState([] as Project[]);
  const [liveProject, setLiveProject] = useState({} as Project);
  const [liveChatMessages, setLiveChatMessages] = useState([] as ChatMessage[]);
  const [userHasQuit, setUserHasQuit] = useState(false);

  const [present] = useIonAlert();

  const [liveMultiplex, setLiveMultiplex] = useState({} as Multiplex);
  const [liveMultiplexDashboards, setLiveMultiplexDasboards] = useState([] as MultiplexDashboard[])
  const [liveDashboard, setLiveDashboard] = useState({} as MultiplexDashboard)
  const [liveFocusedLocalities, setLiveFocusedLocalities] = useState([] as DashboardLocalCount[])

  const initLiveData = (showId: string) => {
    const liveShowListener: Function = API.listenToLiveShow(
      showId,
      (show: Show) => setLiveShow(show)
    );
    const liveShowProjectsListener: Function = API.listenToShowProjects(
      showId,
      (projects: Project[]) => setLiveShowProjects(projects)
    );
    const liveChatMessages: Function = API.listenToLiveChatMessages(
      showId,
      (chatMessages: ChatMessage[]) => setLiveChatMessages(chatMessages)
    );
    rtListenersList.map((listener: Function) => listener());
    setRtListenersList([
      liveShowListener,
      liveChatMessages,
      liveShowProjectsListener
    ]);
  };

  const dismissListeners = () => {
    rtListenersList.map((listener: Function) => listener());
    return true;
  };

  const liveUserDataHandler = async (userData: UserData) => {
    API.saveUserDataOnRemote(userData)
      .then(async () => {
        const storage = new Storage();
        await storage.create();
        storage.set("liveUserData", JSON.stringify(userData));
        setLiveUserData(userData);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const liveUserPledgeHandler = async (
    pledge: Pledge,
    messagePayload?: {
      showId: string;
      userData: UserData;
      messageContent: string;
    }
  ): Promise<boolean> => {
    return new Promise((res, rej) => {
      API.sendPledge(liveShow.id, pledge)
        .then(async () => {
          const storage = new Storage();
          await storage.create();
          storage.set(
            "liveUserPledges",
            JSON.stringify([...liveUserPledges, pledge])
          );
          setLiveUserPledges([...liveUserPledges, pledge]);
          if (messagePayload) {
            API.sendChatMessage(
              messagePayload.showId,
              messagePayload.userData,
              messagePayload.messageContent,
              pledge.amount * 100
            )
              .then(() => {
                res(true);
              })
              .catch((err) => {
                console.log(err);
                rej(false);
              });
          } else {
            res(true);
          }
        })
        .catch((e) => {
          console.log(e);
          rej(false);
        });
    });
  };

  const checkPreviousUserData = async () => {
    const storage = new Storage();
    await storage.create();
    const previousUserData = await storage.get("liveUserData");
    const previousUserPledges = await storage.get("liveUserPledges");
    if (previousUserData) {
      const hasShowEnded = await API.hasShowEnded(
        JSON.parse(previousUserData).eventId
      );
      if (hasShowEnded) {
        await storage.remove("liveUserData");
        await storage.remove("liveUserPledges");
        return;
      }
      present({
        header: "Reprendre le live ?",
        message:
          "Souhaitez-vous revenir dans l'évènement auquel vous participiez ?",
        buttons: [
          {
            text: "Non",
            handler: (d) => {
              storage.remove("liveUserData");
            },
          },
          {
            text: "Oui",
            handler: (d) => {
              const user = new UserData(
                "",
                {},
                false,
                false,
                "",
                "",
                false,
                "",
                null
              );
              user.hydrate(JSON.parse(previousUserData));
              setLiveUserData(user);
              if (previousUserPledges) {
                const recoveredPledges = [];
                for (const pledge of JSON.parse(previousUserPledges)) {
                  const recPledge = new Pledge(
                    "",
                    0,
                    "",
                    {},
                    "",
                    new Date(),
                    false
                  );
                  recPledge.hydrate(pledge);
                  recoveredPledges.push(recPledge);
                }
                setLiveUserPledges(recoveredPledges);
              }
            },
          },
        ],
      });
    }
  };

  const userQuitHandler = () => {
    setUserHasQuit(true);
  };

  useEffect(() => {
    if (liveShow.currentProjectId) {
      liveProjectUnsubscriber.map((listener: Function) => listener());
      const liveProjectListener: Function = API.listenToLiveProject(
        liveShow.id,
        liveShow.currentProjectId,
        (project: Project) => setLiveProject(project)
      );
      setLiveProjectUnsubscriber([liveProjectListener]);
    } else if (liveShow.hasNoProjects) {
      setLiveProject({
        id: "",
        name: liveShow.name,
        position: 0,
        logoUrl: liveShow.logoUrl,
        goal: liveShow.globalShowGoal,
        totalPledgesAmount: liveShow.totalPledgesAmount
      } as Project);
    } else {
      setLiveProject({} as Project);
    }
  }, [
    liveShow.name,
    liveShow.logoUrl,
    liveShow.globalShowGoal,
    liveShow.totalPledgesAmount,
    liveShow.currentProjectId,
    liveShow.backgroundColorLogo
  ]);

  const initMultiplex = (multiplex: Multiplex) => {
    API.listenToLiveMultiplex(
      multiplex.id,
      (multiplex: Multiplex) => setLiveMultiplex(multiplex)
    );
    API.listenToMultiplexDashboards(
      multiplex.id,
      (dashboards: MultiplexDashboard[]) => setLiveMultiplexDasboards(dashboards)
    );
  }

  const liveDashboardHandler = (value: MultiplexDashboard) => {
    setLiveDashboard(value)
  }

  const liveFocusedLocalitiesHandler = (value: DashboardLocalCount[]) => {
    setLiveFocusedLocalities(value)
  }

  return (
    <LiveContext.Provider
      value={{
        rtListenersList,
        liveUserData,
        liveUserDataHandler,
        liveUserPledges,
        liveUserPledgeHandler,
        liveShow,
        liveProject,
        liveChatMessages,
        liveShowProjects,
        initLiveData,
        checkPreviousUserData,
        dismissListeners,
        userHasQuit,
        userQuitHandler,
        liveMultiplex,
        liveMultiplexDashboards,
        liveDashboard,
        liveFocusedLocalities,
        initMultiplex,
        liveDashboardHandler,
        liveFocusedLocalitiesHandler
      }}
    >
      {props.children}
    </LiveContext.Provider>
  );
};

export default LiveContext;
