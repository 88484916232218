import { IonContent, IonPage } from "@ionic/react";

import "./MultiplexSummary.css";
import DashedProgressBar from "../../components/Multiplex/DashedProgressBar/DashedProgressBar";

import { ReactComponent as Pin } from "../../assets/icon/Pin.svg"
import Divider from "../../components/Multiplex/Divider/Divider";
import Counter from "../../components/Multiplex/Counter/Counter";
import {useContext, useEffect, useState} from "react";
import API from "../../services";
import Multiplex from "../../models/Multiplex";
import LiveContext from "../../contexts/live.ctx";
import {RouteComponentProps} from "react-router";
import Show from "../../models/Show";

import { ReactComponent as Heart } from "../../assets/icon/HeartShape.svg"
import {DashboardLocalCount, MultiplexDashboard} from "../../models/Dashboard";
import ContainerWithGradient from "../../components/Multiplex/ContainerWithGradient/ContainerWithGradient";
import getSymbolFromCurrency from "../../helpers/getSymbolFromCurrency";
import formatedNumbers from "../../helpers/formatedNumbersWithDot";

interface CounterPageProps
  extends RouteComponentProps<{
    slug: string;
  }> {}

const MultiplexSummaryPage: React.FC<CounterPageProps> = (props) => {
  const liveCtx = useContext(LiveContext);
  const dashboardId = props.location.search.split("?dashboard=")[1]
  const dashboardToUse = liveCtx.liveMultiplexDashboards.find(dashboard => dashboard.id === dashboardId) || {} as MultiplexDashboard
  const progress = Math.round(dashboardToUse.totalPledgesAmount * 100 / dashboardToUse.goal)
  const [otherPlaces, setOtherPlaces] = useState([] as DashboardLocalCount[])

  useEffect(() => {
    async function fetchShowBySlug() {
      API.getShowBySlug(props.match.params.slug)
        .then((show: Show) => {
          liveCtx.initLiveData(show.id);
        })
        .catch((err) => {
          return console.log(err);
        });
    }
    if (!liveCtx.liveShow.id) {
      fetchShowBySlug();
    }
  }, [liveCtx.liveShow.id]);

  useEffect(() => {
    if (liveCtx.liveShow.multiplexId) {
      API.getMultiplexBySlug(liveCtx.liveShow.multiplexId)
        .then((multiplex: Multiplex) => {
          liveCtx.initMultiplex(multiplex);
        })
        .catch((err) => {
          return console.log(err);
        });
    }
  }, [
    liveCtx.liveShow.multiplexId
  ]);

  useEffect(() => {
    if (liveCtx.liveMultiplex.id && dashboardToUse) {
      API.listenToTopDashboardLocalities(
        liveCtx.liveMultiplex.id,
        dashboardToUse.id,
        setOtherPlaces
      )
    }
  }, [liveCtx.liveMultiplexDashboards])

  return (
    <IonPage className="multiplex-page" data-testid="multiplex-page">
      <IonContent>
        <main
          className="multiplex-summary__main"
          style={{
            color: liveCtx.liveMultiplex.fontColor,
            background: `radial-gradient(at 50% 130%, 
            ${liveCtx.liveMultiplex.backgroundColorLight} 9%, ${liveCtx.liveMultiplex.backgroundColorDark} 70%)`
          }}
        >
          <div className="multiplex-summary__main__header">
            <div className="multiplex-summary__main__multiplex-infos">
              {liveCtx.liveMultiplex.logoUrl && liveCtx.liveMultiplex.logoUrl !== "" && (
                <img
                  src={liveCtx.liveMultiplex.logoUrl}
                  alt="logo"
                />
              )}
              <h1 className="multiplex__main__multiplex-card__title">
                {liveCtx.liveMultiplex.name}
                {liveCtx.liveMultiplex.subtitle !== "" && liveCtx.liveMultiplex.subtitle !== "-" && (
                  <span
                    className="multiplex__main__multiplex-card__title-span"
                    style={{
                      color: liveCtx.liveMultiplex.backgroundColorLight
                    }}
                  >
                      &bull; {liveCtx.liveMultiplex.subtitle}
                    </span>
                )}
              </h1>
            </div>

            <div className="multiplex-summary__main__global">
              <div className="multiplex-summary__main__global__content">
                <Counter
                  label=""
                  labelColor={liveCtx.liveMultiplex.fontColorHighlight}
                  count={ liveCtx.liveMultiplex.totalPledgesAmount / 100}
                  hasSpecialFormat
                  isBig
                />
              </div>
              <Heart
                className="multiplex-summary__main__global__heart"
                style={{ color: liveCtx.liveMultiplex.backgroundColorLight }}
              />
            </div>
          </div>

          <div className="multiplex-summary__main__dashboard-header">
            {(dashboardToUse.logoUrl && dashboardToUse.logoUrl !== "") ? (
              <img
                src={dashboardToUse.logoUrl}
                alt="logo"
              />
            ):(
              <p>{dashboardToUse.name}</p>
            )}
          </div>

          <div className="multiplex-summary__main__container">
            <div className="multiplex-summary__main__current-project">
              <div className="multiplex-summary__main__current-project__card">
                <div
                  className="multiplex-summary__main__location-pile"
                  style={{
                    backgroundColor: liveCtx.liveMultiplex.backgroundColorLight
                  }}
                >
                  <Pin/>
                  {liveCtx.liveShow.place}
                </div>
                <Counter label="Levée en cours"
                         labelColor={liveCtx.liveMultiplex.fontColorHighlight}
                         count={liveCtx.liveShow.totalPledgesAmount / 100}
                         isBig
                />
              </div>
              {otherPlaces.length > 0 &&
                <Divider />
              }
            </div>

            {otherPlaces.length > 0 && (
              <div className="multiplex-summary__main__highlight-project">
                <div className="multiplex-summary__main__highlight-project__card">
                  <div
                    className="multiplex-summary__main__location-pile"
                    style={{
                      border: `2px solid ${liveCtx.liveMultiplex.backgroundColorLight}`
                    }}
                  >
                    <Pin/> Autres villes
                  </div>
                  <div className="multiplex-summary__main__counter-list">
                    {otherPlaces.slice(0, 3).map(locality =>
                      <Counter label={locality.place}
                               labelColor={liveCtx.liveMultiplex.fontColorHighlight}
                               count={locality.totalPledgesAmount / 100}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}

            <ContainerWithGradient className="multiplex-summary__main__dashboard-count">
              <>
                <div className="multiplex-summary__main__dashboard-count__title">
                  Levée totale sur le projet
                </div>

                {dashboardToUse.goal && dashboardToUse.totalPledgesAmount ? (
                  <div className="multiplex-summary__main__dashboard-count__count">
                    {formatedNumbers(dashboardToUse.totalPledgesAmount / 100)} {getSymbolFromCurrency(liveCtx.liveShow.pledgesCurrency)}
                    {progress && (
                      <span
                        style={{
                          color: liveCtx.liveMultiplex.fontColorHighlight
                        }}
                      > &bull; {progress} %</span>
                    )}
                  </div>
                ) : (
                  <div className="multiplex-summary__main__dashboard-count__count">
                    0 {getSymbolFromCurrency(liveCtx.liveShow.pledgesCurrency)}
                    <span
                      style={{
                        color: liveCtx.liveMultiplex.fontColorHighlight
                      }}
                    > &bull; 0 %</span>
                  </div>
                )}

                {dashboardToUse.goal ? (
                  <div className="multiplex-summary__main__dashboard-count__goal">
                    Objectif : {formatedNumbers(dashboardToUse.goal / 100)} {getSymbolFromCurrency(liveCtx.liveShow.pledgesCurrency)}
                  </div>
                ) : (
                  <div className="multiplex-summary__main__dashboard-count__goal">
                    Objectif : 0 {getSymbolFromCurrency(liveCtx.liveShow.pledgesCurrency)}
                  </div>
                )}
              </>
            </ContainerWithGradient>
          </div>

          <div className="multiplex-summary__main__container">
            <DashedProgressBar
              colorBase={liveCtx.liveMultiplex.fontColor}
              colorHighlight={liveCtx.liveMultiplex.fontColorHighlight}
              count={dashboardToUse.totalPledgesAmount}
              objective={dashboardToUse.goal}
            />
          </div>
        </main>
      </IonContent>
    </IonPage>
  )
}

export default MultiplexSummaryPage