import { useIonModal } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import HomeContext from "../../../contexts/home.ctx";
import LiveContext from "../../../contexts/live.ctx";
import Show from "../../../models/Show";
import ShowDetailModal from "../../Modals/ShowDetailModal/ShowDetailModal";
import ShowCard from "../../Shows/ShowCard/ShowCard";
import "./HomeShowsGrid.css";

interface ContainerProps {}

const HomeShowsGrid: React.FC<ContainerProps> = (props) => {
  const homeCtx = useContext(HomeContext);
  const liveCtx = useContext(LiveContext);
  const [showsOfSegment, setShowsOfSegment] = useState([] as Show[]);
  const [selectedShow, setSelectedShow] = useState({});

  const history = useHistory();

  const [present, dismiss] = useIonModal(ShowDetailModal, {
    show: selectedShow,
    liveCtx,
    onDismiss: () => {
      setSelectedShow({});
      dismiss();
    },
  });

  useEffect(() => {
    if (liveCtx.liveUserData.showSlug) {
      dismiss();
      setTimeout(() => {
        history.push(`/${liveCtx.liveUserData.showSlug}/live`);
      }, 350);
    } else {
      liveCtx.checkPreviousUserData();
    }
  }, [liveCtx.liveUserData]);

  useEffect(() => {
    switch (homeCtx.currentSegment) {
      case "TODAY":
        setShowsOfSegment(homeCtx.allTodayShows);
        break;
      case "COMING":
        setShowsOfSegment(homeCtx.allComingShows);
        break;
      case "PAST":
        setShowsOfSegment(homeCtx.allPastShows);
        break;
      default:
        setShowsOfSegment([]);
    }
  }, [
    homeCtx.allTodayShows,
    homeCtx.allComingShows,
    homeCtx.allPastShows,
    homeCtx.currentSegment,
  ]);

  return (
    <div className="home-shows-grid" data-testid="home-shows-grid">
      {showsOfSegment.map((show: Show) => (
        <ShowCard
          onClick={() => {
            setSelectedShow(show);
            present();
          }}
          show={show}
          key={show.id}
        />
      ))}
    </div>
  );
};

export default HomeShowsGrid;
